.assessment-documents {}

.assessment-document {
  display: inline-block;
  margin-right: 20px;
}

.assessment-document__download,
.assessment-document__delete {
  display: inline-block;

  a {
    display: inline-block;
    color: white;
  }
}

.assessment-document__download {
  @extend .btn, .bg-orange;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 10px;
}

.assessment-document__delete > a {
  @extend .btn, .bg-grey;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 10px;
}

.assessment-document__delete .assessment-document__delete_after {
  display: none;
}

.assessment-document.is-deleting {
  .assessment-document__download > a {
    width: 0px;
    opacity: 0;
    font-size: 0;
    pointer-events: none;
  }

  .assessment-document__delete_before {
    display: none;
  }
  .assessment-document__delete_after {
    display: inline-block;
  }
}