.js-fade-toggle-box {
	position: relative;
	height: 240px;
	padding: 30px 30px 50px;
	background-color: $ultra_light_grey;
	overflow: hidden;

	* {
		font-size: 16px;
	}

	p {
		margin: 0 0 1rem;
	}

	ul {
		display: block;
		padding: 0 0 0 1rem;
		margin: 0 0 0 1rem;
		list-style: disc;

		li {
			margin: 0 0 5px;
		}
	}
}

.js-fade-toggle-box__toggler {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background: $ultra_light_grey;
	padding: 15px 30px;
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	user-select: none;

	&:hover {
		text-decoration: none;
	}

	&::before {
		position: absolute;
		bottom: 100%;
		left: 0;
		width: 100%;
		height: 30px;
		content: "";
		background-image: linear-gradient(0deg, $ultra_light_grey, rgba($ultra_light_grey, 0));
	}
}
