/* Layout: _blog-featured.scss
---------------------------------------------------------------------------------- */
.blog-featured {
	border: 1px solid $blog-grey;
	border-top: 1px solid $orange;
	position: relative;
}

@media (min-width: 768px) {
	.blog-featured {
		display: flex;
		flex-direction: row-reverse;
	}
}

/*------------------------------------
	Image
------------------------------------*/
.blog-featured__image {
	background-color: $white;
	border: 1px solid $blog-grey;
	overflow: hidden;

	img {
		display: block;
	}
}

@media (min-width: 768px) {
	.blog-featured__image {
		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
}

/*------------------------------------
	Content
------------------------------------*/
.blog-featured__content {
	background-color: $blog-grey;
	padding: 30px;
}

@media (min-width: 768px) {
	.blog-featured__content {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 319px;
		min-height: 403px;
	}
}

/*------------------------------------
	Date
------------------------------------*/
.blog-featured__date {
	color: $orange;
	display: block;
	font-size: 14px;
	margin-bottom: 1em;
}

/*------------------------------------
	Title
------------------------------------*/
.blog-featured__title {
	font-size: 21px;
	line-height: 1.33;
	margin-bottom: 15px;

	a {
		font-family: $medium_font;
		transition: 300ms ease-in-out color;

		:where(.blog-featured:hover) & {
			color: $orange;
		}

		&:before {
			content: '';
			inset: 0;
			position: absolute;
		}
	}
}

/*------------------------------------
	Summary
------------------------------------*/
p {
	.blog-featured & {
		font-size: 16px;
		line-height: 1.5;
	}
}
