// main: ../style.scss
.partner_request__wrapper {
  line-height: 31px !important;
}

.partner_request__title {
  margin-bottom: 8px;
  display: inline-block;
}

.accordion_item__description label {
  font-family: $medium_font !important;
}

.partner_request {
  font-family: $light_font !important;

  .partner_request__looking,
  .partner_request__offering {
    font-family: $light_font !important;
    padding: 8px;
    margin-right: 8px;
  }
  .partner_request__looking {
    background-color: rgba($brand_green, 0.8);
  }
  .partner_request__offering {
    background-color: rgba($brand_orange, 0.8);
  }
}

.partner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#494949, 0.71);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 9999;

  .partner-panel {
    width: 100%;
    max-width: 895px;
    position: absolute;
    border-radius: 5px;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .partner-header {
      height: 96px;
      background-color: #D7D7D7;
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      justify-content: center;
      min-height: 0;
      padding-top: 6px;
      box-sizing: border-box;

      h3 {
        font-size: 24px;
      }

      .partner-legend {
        font-size: 24px;
        margin-left: 24px;
      }

      .partner-close {
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: 18px;
        padding-right: 16px;

        &::after {
          content: 'X';
          position: absolute;
          right: 0;
          top: 2px;
          font-size: 18px;
          font-family: $medium_font;
        }
      }
    }

    .partner-form {
      padding: 16px;

      fieldset {
        padding: 0;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        .field {
          &.optionset {
            padding-top: 32px;
            padding-bottom: 26px;
            padding-left: 120px;
            padding-right: 106px;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            label {
              text-align: center;
              font-size: 18px;
              font-family: $default-font;

              strong {
                font-family: $medium_font;
              }
            }

            input[type=radio] {
              -webkit-appearance: radio;
              -moz-appearance: radio;
              -ms-appearance: radio;
            }

            ul.optionset {
              display: flex;
              flex-direction: row;
              margin-top: 16px;
              border: 1px solid #E5E5E5;
              border-width: 0 0 1px 1px;

              li {
                width: 90px;
                height: 83px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #FBFBFB;
                border: 1px solid #E5E5E5;
                border-width: 1px 1px 0 0;
                position: relative;

                label {
                  order: 1;
                  margin: 0;
                  font-size: 20px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  padding-top: 18px;
                }

                input {
                  order: 2;
                  margin-top: 30px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .Actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
      }
    }
  }
}

.partners__content_empty {
  border: 1px solid #D1D1D1;
  background-color: #F0F0F0;
  text-align: center;
  padding: 18px;
}
