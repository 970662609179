.contact_details
{
    font-size: 0;

    & + h2
    {
        margin-top: 50px;
    }
    // end .contact_details + h2
}
// end .contact_details

    .contact_details__column
    {
        display: inline-block;
        vertical-align: top;
        width: 50%;

        &:first-child
        {
            padding-right: 4px;
        }
        // end .contact_details__column:first-child

        &:last-child
        {
            padding-left: 4px;
        }
        // end .contact_details__column:last-child

        @media screen and (max-width: 640px)
        {
            width: 100%;
            padding-right: 0 !important;
            padding-left: 0 !important;

            &:first-child
            {
                padding-bottom: 8px;
            }
            // end .contact_details__column:first-child
        }
        // end @max-width: 640px
    }
    // end .contact_details__column

        .contact_details__block
        {
            margin-bottom: 8px;
            padding: 25px 15px 25px 25px;

            background-color: $white;
            border: 1px solid $light_grey;

            h3
            {
                color: $brand_green;

                font-family: $medium_font;
                font-size: 17px;

                & + ul
                {
                    margin-top: 15px;
                }
                // end h3 + ul
            }
            // end h3

            p
            {
                margin-top: 15px;
                margin-bottom: 0;

                font-size: 14px;
            }
            // end p

            ul
            {
                margin-top: 23px;
                margin-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                font-size: inherit;
                line-height: inherit;

                li
                {
                    padding: 2px 0;

                    a
                    {
                        font-size: 14px;

                        span
                        {
                            display: inline-block;
                            vertical-align: middle;
                            padding-top: 4px;
                            padding-left: 5px;
                        }
                        // end span

                        &::before
                        {
                            content: '';

                            display: inline-block;
                            vertical-align: middle;
                            width: 16px;
                            height: 16px;

                            background-image: url('../../images/Pointer.svg');
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                        }
                        // end a::before

                        &[href*="mailto:"]
                        {
                            &::before
                            {
                                background-image: url('../../images/Mail_green.svg');
                            }
                            // end a[href*="mailto:"]::before
                        }
                        // end a[href*="mailto:"]

                        &[href*="twitter"]
                        {
                            &::before
                            {
                                background-image: url('../../images/twitter.svg');
                            }
                            // end a[href*="twitter"]::before
                        }
                        // end a[href*="twitter"]

                        &[href*="linkedin"]
                        {
                            &::before
                            {
                                background-image: url('../../images/Linkedin.svg');
                            }
                            // end a[href*="linkedin"]::before
                        }
                        // end a[href*="linkedin"]

                        &:hover
                        {
                            span
                            {
                                text-decoration: underline;
                            }
                            // end span
                        }
                        // end a:hover
                    }
                    // end a
                }
                // end li
            }
            // end ul

            &:last-child
            {
                margin-bottom: 0;
            }
            // end .contact_details__block:last-child
        }
        // end .contact_details__block