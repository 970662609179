.submission_status
{
    &.submission_status--highlight
    {
        color: $brand_orange;

        font-family: $medium_font;
    }
    // end .submission_status.submission_status--highlight

    &.submission_status--grade {
      color: $white;
      vertical-align: middle;
      font-family: $medium_font;

      &[data-grade="A"]
      {
          background-image: radial-gradient(circle at 50%, $grade_a 0px, $grade_a 20px, transparent 21px);
      }
      // end .submission_status.submission_status--grade[data-grade="A"]

      &[data-grade="B"]
      {
          background-image: radial-gradient(circle at 50%, $grade_b 0px, $grade_b 20px, transparent 21px);
      }
      // end .submission_status.submission_status--grade[data-grade="B"]

      &[data-grade="C"]
      {
          background-image: radial-gradient(circle at 50%, $grade_c 0px, $grade_c 20px, transparent 21px);
      }
      // end .submission_status.submission_status--grade[data-grade="C"]

      &[data-grade="D"]
      {
          background-image: radial-gradient(circle at 50%, $grade_d 0px, $grade_d 20px, transparent 21px);
      }
      // end .submission_status.submission_status--grade[data-grade="D"]

      &[data-grade="W"]
      {
          background-image: radial-gradient(circle at 50%, $grade_w 0px, $grade_w 20px, transparent 21px);
      }
      // end .submission_status.submission_status--grade[data-grade="W"]
    }
    // end .submission_status.submission_status--grade
}
// end .submission_status