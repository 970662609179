// compileCompressed: ../css/style.css

// Master Styles
@import 'reset';
@import 'color_palette';
@import 'fonts';
@import 'functions';
@import 'mixins';
@import 'forms';
@import 'buttons';

// Breakpoints
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

// Components
@import 'partials/submission_status';
@import 'partials/submission_notification';

@import 'partials/file';
@import 'partials/wysiwyg';

// Partial
@import 'layout';
@import 'partials/header';
@import 'partials/primary_navigation';
@import 'partials/footer';
@import 'partials/partner_strip';
@import 'partials/submissions';
@import 'partials/comment_panel';
@import 'partials/supporting_documents_panel';
@import 'partials/rating';
@import 'partials/landing';
@import 'partials/page';
@import 'partials/accordion';
@import 'partials/contact_details';
@import 'partials/sidebar_block';
@import 'partials/partners';
@import 'partials/account';
@import 'partials/judges';
@import 'partials/tooltip';
@import 'partials/flash';
@import 'partials/assessment';
@import 'partials/assessment-area';
@import 'partials/toggle-fade-box';
@import 'partials/solver-submission-modal';
@import 'partials/solver_profiles';
@import 'partials/blog/blog';
