/* Layout: _blog-pagination.scss
---------------------------------------------------------------------------------- */
.blog-pagination {
	border-top: 1px solid $blog-grey;
	font-size: 16px;
	margin: 24px 0 0;
	padding-top: 24px;
}

/*------------------------------------
	Menu
------------------------------------*/
.blog-pagination__menu {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;

	a {
		color: $orange;

		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
}
