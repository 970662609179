// main: ../style.scss

.review-submissions {
    position: relative;
    width: 100%;
    border-radius: 5px;
    border: 1px $light_grey solid;
    margin: 40px 0;
    .submission {
        display: inline-block;
        width: 100%;
        padding: 0 40px;
        background-color: $white;
        border-bottom: $light_grey 1px solid;
        .title {
            font-size: 20px;
        }
        .time-stamp {
            font-size: 14px;
        }
        &:first-child {
            border-radius:  5px 5px 0 0;
        }
        &:last-child {
            border-radius: 0 0 5px 5px;
            border-bottom: none;
        }
        .left
        {
            display: inline-block;
            width: 39%;
            vertical-align: middle;
        }
        .right
        {
            display: inline-block;
            width: 60%;
            text-align: right;
        }
    }
}

.submission-info {
    display: block;
    // align-items: center;
    background-color: $white;
    border: $light_grey 1px solid;
    border-radius: 5px;
    padding: 40px;

    .personal
    {
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }

    .actions
    {
        display: inline-block;
        width: 49%;
    }

    h3 {
        font-size: 22px;
    }
    a {
        display: block;
        margin: 0 0 20px 0;
    }
    p {
        margin: 20px 0;
    }
}

.submission-header {
    flex-direction: column;

    p:last-child {
        margin-bottom: 0px;
    }
}

.submission-disclaimer {
    p {
        font-weight: bold;
        margin: 0px;
    }
}

.submission-rated {
    display: inline-block;
    margin-right: 50px;
    //margin-top: 16px;
    vertical-align: middle;

    img {
        display: inline-block;
        margin-right: 12px;
        vertical-align: top;
    }

    p {
        display: inline-block;
        font-size: 16px;
        line-height: normal;
        text-align: left;
    }
}

.submission-complete {
    color: #8cbb3d;
    margin-right: 20px;
    font-size: 0;
    .fa {
        font-size: 30px;
    }
}

.milestone__reviewers-list {
	margin-top: 30px;
	padding: 20px;
	background-color: $light_grey;

	> h3 {
		font-size: 24px;
	}
}

.milestone__reviewers-list__list {
	margin-top: 20px;
}

.milestone__reviewers-list__list-item {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	font-size: 20px;
	background-color: $white;
	border: 2px solid $mid_grey;

	&:not(:last-child) {
		margin-bottom: 18px;
	}

	span.review-title {
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 18px 22px;

		span {
			padding: 5px 0;

			&:first-child {
				&::before {
					content: '>';
					margin-right: 12px;
					font-family: $medium_font;
				}
			}
		}
	}

	span.review-status {
		display: flex;
		align-items: center;
		flex-basis: 100%;
		color: $white;
		background: $mid_grey;
		padding: 10px 20px;
	}

	&--complete-locked {
		border-color: $bad_red;

		span.review-status {
			background-color: $bad_red;
		}
	}

	&--under-review {
		border-color: $brand_orange;

		span.review-status {
			background-color: $brand_orange;
		}
	}

	&--complete-open {
		border-color: $brand_green;

		span.review-status {
			background-color: $brand_green;
		}
	}
}

@media screen and (min-width: 600px) {
	.milestone__reviewers-list__list-item {
		span.review-status {
			flex-basis: 187px;
		}
	}
}
