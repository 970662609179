.assessment-area-panel {
	background-color: $white;
	margin-top: 30px;
	padding: 20px;
}

.assessment__judge-head {
	display: block;
	width: 100%;
	background-color: $mid_grey;
	color: $white;
	text-align: left;
	font-size: 24px;
	padding: 20px 30px;
}

.assessment__dates {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 30px;
}

.assessment__date {
	width: 100%;
	padding: 8px 0;
	font-size: 20px;
	font-weight: bold;

	.assessment__date__label {
		color: $brand_green;
	}
}

@media screen and (min-width: 560px) {
	.assessment__date {
		width: 50%;
	}
}

@media print {
	.assessment__date {
		width: 50%;
	}
}

.assessment__meta-info {
	margin: 40px auto 10px;

	h2.assessment__meta-info__title {
		font-size: 24px;
		font-weight: bold;
		text-align: left;
		width: 100%;
		max-width: unset;
		color: $black;
		line-height: 1.2;
	}
}

.ms2-assessment__form {
	.Actions {
		display: flex;
		justify-content: space-between;
	}
}

.ms2-assessment__question-heading {
	position: relative;
	display: flex;
	align-items: flex-start;
	background-color: $mid_grey;
	padding: 22px;
	color: $white;
	page-break-inside: avoid;

	&.toggle {
		cursor: pointer;
		user-select: none;

		&::after {
			position: absolute;
			top: 50%;
			right: 22px;
			transform: translateY(-50%);
			content: '';
			width: 40px;
			height: 40px;
			background: $white url("../../images/arrow.svg") center / 18px auto no-repeat;
			border-radius: 100%;
		}
	}

	&__number {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $brand_green;
		width: 47px;
		height: 47px;
		margin-right: 20px;
		color: $white;
		font-size: 31px;
		font-weight: bold;
		padding-top: 5px;
	}

	&--hide-key {
		.ms2-assessment__question-heading__number {
			font-size: 0;
			background: $brand_green url('../../images/Document.png') 50% / auto 60% no-repeat;
		}
	}

	&__text {
		font-size: 20px;
		width: 80%;
		max-width: 693px;
		line-height: 1.3;
	}

	// Hacky - sorry :D
	& + .fieldholder-small {
		display: flex !important;
		align-items: center;
		margin: 20px 0 !important;

		label {
			padding: 0 !important;
			width: 30% !important;
		}

		ul {
			width: 70% !important;
		}
	}

}

.ms2-assessment__question {
	& > .fieldgroup {
		padding: 0 !important;
		border: 2px solid $mid_grey !important;

		.fieldgroup-field:nth-child(2) {
			> .fieldholder-small {
				display: flex !important;
				align-items: center;
				margin: 20px 0 !important;

				label {
					padding: 0 !important;
					width: 30% !important;
				}

				ul {
					width: 70% !important;
				}
			}
		}
	}

	div.fieldgroup-field {
		&:not(.first) {
			display: none;
			padding: 10px 35px;
		}

		&.last {
			padding-bottom: 30px;
		}

		.fieldholder-small {
			border: 0 !important;
			padding-bottom: 0 !important;
		}

		label {
			margin-top: 10px;
			font-size: 20px;
			display: block;
			width: 100%;
			text-align: left;
		}

		textarea {
			margin-bottom: 0;
		}

		ul.optionset {
			display: flex;

			li {
				margin: 0 14px 0 0;

				label {
					display: inline-block;
					width: auto;
					margin-left: 5px;
				}
			}
		}

		.field.fileattachment {
			padding: 20px 0;
			border: 0;

			label {
				font-weight: bold;
			}

			.dropzone-holder {
				border: 2px dashed $light_grey;
				padding: 10px;
				background: $ultra-light-grey;
			}
		}
	}

	&--open {
		div.fieldgroup-field {
			&:not(.first) {
				display: block;
			}
		}

		.ms2-assessment__question-heading {
			&.toggle {
				&::after {
					transform: translateY(-50%) rotate(.5turn);
				}
			}
		}
	}
}

.ms2-assessment__sub-question {
	.fieldholder-small {
		padding: 0 !important;
	}
}

.ms2-assessment__save-action {
	margin: 0 !important;
	right: 0;
}

// Print Styles
@media print {
	.header__header_buttons,
	.primary_navigation,
	.judges__judges_title,
	.judges__judges_title + p,
	.partner-strip,
	.footer,
	.assessment-area__actions,
	.assessment-area__appendix,
	.loggedin-bar {
		display: none;
	}

	.content-container {
		padding-top: 0;
	}

	.ms2-assessment__question div.fieldgroup-field:not(.first) {
		display: block;
	}

	.ms2-assessment__question-heading.toggle::after {
		display: none;
	}

	.assessment__judge-head {
		padding: 20px 0 0;
		color: $black;
		background: none;
	}

	.ms2-assessment__question-heading {
		color: $black;
		border: 1px solid $black;
		background: none;
	}

	.fieldholder-small {
		page-break-inside: avoid;
	}

	.assessment__date {
		font-size: 17px;
	}

	.assessment-area-panel {
		max-width: 990px;
		margin: 0 auto;
	}

	.ms2-assessment__question div.fieldgroup-field label {
		font-size: 16px;
	}

	.ms2-assessment__question-heading__text {
		font-size: 18px;
	}
}
