/* Layout: _blog-posts.scss
---------------------------------------------------------------------------------- */
.blog-post-card {
	position: relative;

	&--large {
		:where(li:not(:last-child)) & {
			margin-bottom: 62px;
		}
	}
}

/*------------------------------------
	Image
------------------------------------*/
.blog-post-card__image {
	background-color: $white;
	border: 1px solid $blog-grey;
	margin-bottom: 28px;
	overflow: hidden;

	:where(.blog-post-card:not(.blog-post-card--large)) & {
		border-top: 1px solid $orange;
	}

	img {
		display: block;
		width: 100%;
	}
}

/*------------------------------------
	Date
------------------------------------*/
.blog-post-card__date {
	color: $orange;
	display: block;
	font-size: 14px;
	margin-bottom: 1em;
}

/*------------------------------------
	Title
------------------------------------*/
.blog-post-card__title {
	font-size: 18px;
	line-height: 1.33;
	margin-bottom: 15px;

	a {
		font-family: $medium_font !important;
		transition: 300ms ease-in-out color;

		:where(.blog-post-card:hover) & {
			color: $orange;
		}

		&:before {
			content: '';
			inset: 0;
			position: absolute;
		}
	}
}

@media (min-width: 576px) {
	.blog-post-card__title {
		.blog-post-card--large & {
			font-size: 21px;
		}
	}
}

/*------------------------------------
	Summary
------------------------------------*/
p {
	.blog-post-card & {
		font-size: 16px;
		line-height: 1.5;
	}
}
