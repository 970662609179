/* Layout: _blog-image-panel.scss
---------------------------------------------------------------------------------- */
/*------------------------------------
	Outside
------------------------------------*/
.blog-image-panel__outside {
	height: 33vh;
	margin: 40px 0;
}

@media (min-width: 768px) {
	.blog-image-panel__outside {
		margin: 70px 0;
	}
}

/*------------------------------------
	Inside
------------------------------------*/
.blog-image-panel__inside {
	height: 33vh;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
}

/*------------------------------------
	Image
------------------------------------*/
.blog-image-panel__image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 33vh;
	position: relative;
	transition: 100ms ease-out;
}

@media (max-width: 991px) {
	.blog-image-panel__image {
		background-position-y: 0 !important;
	}
}

@media (min-width: 992px) {
	.blog-image-panel__image {
		background-attachment: fixed;
	}
}
