.panel--landing
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .site_width
    {
        width: $site_width;
        margin: 0 auto;

        font-size: 0;

        @media screen and (max-width: $site_width)
        {
            width: 100%;
        }
        // @max-width: $site_width
    }
    // end .site_width
}
// end .panel--landing

    .landing__landing_main
    {
        position: relative;
        z-index: 1;

        display: inline-block;
        vertical-align: top;
        width: (100% / 3) * 2;
        padding: 0 7px;

        @media screen and (max-width: 768px)
        {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
        }
        // end @max-width: 896px
    }
    // end .landing__landing_main

        .landing_main__prize
        {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;

            width: (100% / 3);
            height: 100%;
        }
        // end .landing_main__prize

            .landing_main__prize_image
            {
                position: absolute;
                top: 0;
                left: 50%;

                max-width: 100%;
                max-height: 100%;

                @include translate(-50%);
            }
            // end .landing_main__prize_image

        .landing_main__header
        {
            min-height: 0;
            padding: 30px 30px 30px (100% / 3);

            background-color: $white;
            border-top: none;
        }
        // end .landing_main__header

            .landing_main__header_title
            {
                margin-top: 0;
                margin-bottom: 0;

                color: $brand_green;

                font-family: $light_font;
                font-size: 28px;
                line-height: 34px;
                text-transform: uppercase;

                span
                {
                    font-family: $medium_font;
                }
                // end span

                @media screen and (max-width: 640px)
                {
                    font-size: 24px;
                    line-height: 28px;
                }
                // end @max-width: 640px

                @media screen and (max-width: 512px)
                {
                    font-size: 20px;
                    line-height: 24px;
                }
                // end @max-width: 512px
            }
            // end .landing_main__header_title

        .landing_main__banner
        {
        }
        // end .landing_main__banner

            .landing_main__banner_image
            {
                width: 100%;
            }
            // end .landing_main__banner_image

        .landing_main__content
        {
            padding: 30px 30px 30px (100% / 3);

            p
            {
                font-size: 15px;
                line-height: 24px;
            }
            // end p

            strong {
                font-family: "DINMedium";
            }

            em {
                font-style: italic;
            }

            @media screen and (max-width: 512px)
            {
                padding-left: 0;
            }
            // end @max-width: 512px
        }
        // end .landing_main__content

    .landing__landing_sidebar
    {
        display: inline-block;
        vertical-align: top;
        width: (100% / 3);
        padding: 0 7px;

        @media screen and (max-width: 768px)
        {
            width: 100%;
            margin-top: 40px;
            padding-right: 0;
            padding-left: 0;
        }
        // end @max-width: 896px
    }
    // end .landing__landing_sidebar
