// main: style.scss

a {
    text-decoration: none;
    color: $black;
}

.btn {
    // height: 50px;
    // line-height: 55px;

    font-size: 16px;
    font-family: $medium-font;
    display: inline-block;
    // width: inherit;
    border-radius: 60px;
    margin: 30px auto;
    color: $white;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-position: 5% center;
    -webkit-transition: background-color .25s; /* Safari */
    transition: background-color .25s;
    border: none;
    outline: none;
    padding: 15px 20px 10px 20px;

    &:hover
    {
        cursor: pointer;
    }
    // end .btn:hover
}

.btn > img {
    vertical-align: middle;
}


.action {
	@extend .btn;
    background-color: $brand_green;
    width: auto;
    padding-left: 56px;
    background-position: 10px center;
    background-image: url('../../images/Arrow Right.svg');
    &:hover {
		cursor: pointer;
        background-color: lighten($brand_green, 10);
    }
    &:active {
		background-color: lighten($brand_green, 10);
    }
    &.busy {
		background-color: #D7D7D7;
        background-image: url('../../images/Loading.svg');
        background-size: 38px;
	}
	&:disabled {
		background-color: $light_grey;
	}
}

.bg-white
{
	background-color: $white;
	color: $black;

	&:hover
	{
		cursor: pointer;
		background-color: darken($white, 10%);
	}
	// end .bg-white:hover
}
// end .bg-white

.bg-grey
{
	background-color: $nav_grey_dark;
	color: $black;

	&:hover
	{
		background-color: lighten($nav_grey_dark, 10%);
	}
	// end .bg-grey:hover
}
// end .bg-grey

.bg-dark-grey
{
	background-color: $dark_grey;
	color: $white;

	&:hover
	{
		background-color: lighten($dark_grey, 10%);
	}
	// end .bg-grey:hover
}
// end .bg-grey

.bg-green {
	background-color: $brand_green;
	&:hover {
		cursor: pointer;
		background-color: lighten($brand_green, 10);
	}
	&:active {
		background-color: lighten($brand_green, 10);
	}
}

.bg-orange {
	background-color: $brand_orange;
	&:hover {
		background-color: lighten($brand_orange, 10);
	}
	&:active {
		background-color: darken($brand_green, 10);
	}
}
.btn-download {
    @media (max-width: 850px) {
        margin: 20px auto;
    }
    font-size: 16px;
    // line-height: 55px;
    width: auto;
    background-image: url('../../images/Document.svg');
    background-size: 18px auto;
    background-position: 20px center;
    padding: 20px 20px 15px 50px;
}

.btn-online {
    @extend .btn-download;
    background-image: url('../../images/Laptop.svg');
}

.btn-upload {
    @extend .btn-download;
    background-image: url('../../images/Upload Document.svg');
    font-size: 14px;
}

.btn-link {
    @extend .btn-download;
    background-image: url('../../images/open-in-new-window--white.svg');
    font-size: 14px;
}

.btn-mail {
    @extend .btn-download;
    background-image: url('../../images/Mail.svg');
    background-position: 9% center;
    background-size: 24px auto;
    font-size: 14px;
}

.btn-next {
    @extend .btn-download;
    font-size: 16px;
    background-image: url('../../images/DownArrow.svg');
}

.btn-online {
    @extend .btn-download;
    background-image: url('../../images/Laptop.svg');
    padding: 20px 20px 15px 70px;
}

.btn-rate {
    @extend .btn-download;
    background-image: url('../../images/Rate.svg');
    text-align: left;
    background-position: 20px center;
}

.btn-user {
    @extend .btn-download;
    background-image: url('../../images/user-sign-in.svg');
    background-position: 9% center;
    background-size: 22px auto;
}

.btn-login {
    margin-left: 12px;
}

.btn-login {
    margin-left: 12px;
}
