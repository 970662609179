/* Layout: _blog-section.scss
---------------------------------------------------------------------------------- */
.blog-section {
	background-color: $white;
	border: 1px solid $light_grey;
	padding: 53px 90px;
	position: relative;

	&--continued {
		padding: 40px 80px 70px;
	}

	&:where(:not(.blog-section--continued)) {
		&:before {
			background-color: $orange;
			content: '';
			height: 5px;
			inset: -1px -1px auto;
			position: absolute;
		}
	}

	&--form {
		background-color: $blog-grey;

		&:before {
			background-color: $brand_green;
		}
	}

	&--no-border-top {
		&:before {
			content: unset;
		}
	}

	.twitter-timeline {
		iframe {
			width: 100% !important;
		}
	}

	h1 {
		font-family: $medium_font;
		font-size: 28px;
		line-height: (34/28);
		margin: 0;
		max-width: none;
	}
}

@media (max-width: 768px) {
	.blog-section {
		padding: 50px 40px;

		&--continued {
			padding: 40px;
		}
	}
}

@media (max-width: 640px) {
	.blog-section {
		padding: 30px 20px;
	}
}

/*------------------------------------
	Top
------------------------------------*/
.blog-section__top {
	align-items: center;
	border-bottom: 1px solid $blog-grey;
	display: flex;
	flex-wrap: wrap;
	gap: 36px 24px;
	justify-content: space-between;
	margin: 0 0 24px;
	padding-bottom: 13px;
}

/*------------------------------------
	Title
------------------------------------*/
h2.blog-section__title {
	color: $orange;
	font-family: $medium_font;
	font-size: 25px;
	margin: 0;
}

@media (min-width: 576px) {
	h2.blog-section__title {
		font-size: 34px;
	}
}

/*------------------------------------
	Downloads grid
------------------------------------*/
.blog-section__downloads-grid {
	align-items: start;
	display: grid;
	font-size: 16px;
	gap: 10px 36px;
}

@media (min-width: 768px) {
	.blog-section__downloads-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*------------------------------------
	Downloads list
------------------------------------*/
.blog-section__downloads-list {
	align-items: start;
	display: grid;
	gap: 10px;
}

/*------------------------------------
	Downloads item
------------------------------------*/
.blog-section__downloads-item {
	align-items: center;
	border: 1px solid $blog-grey;
	display: grid;
	font-size: 16px;
	gap: 18px;
	grid-template-columns: 26px 1fr;
	min-height: 50px;
	padding: 8px;
	position: relative;
	transition: 300ms ease-in-out background-color;

	&:hover {
		background-color: $blog-grey;
	}

	svg {
		fill: $dark_grey;
	}

	a {
		cursor: pointer;
		font-family: $medium_font;
		line-height: 1.5;

		&:before {
			content: '';
			inset: 0;
			position: absolute;
		}
	}
}

/*------------------------------------
	Gallery list
------------------------------------*/
.blog-section__gallery-list {
	display: grid;
	gap: 24px 10px;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	margin-top: 24px;
}

@media (min-width: 576px) {
	.blog-section__gallery-list {
		grid-template-columns: repeat(3, 1fr);
		margin-top: 58px;
	}
}

@media (min-width: 768px) {
	.blog-section__gallery-list {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 992px) {
	.blog-section__gallery-list {
		gap: 36px;
		grid-template-columns: repeat(5, 1fr);
	}
}

/*------------------------------------
	Gallery item
------------------------------------*/
.blog-section__gallery-item {
	font-size: 14px;
	position: relative;
	text-align: center;

	img {
		display: block;
		margin-bottom: 12px;
	}

	a {
		cursor: pointer;
		line-height: 1.5;

		&:before {
			content: '';
			inset: 0;
			position: absolute;
		}

		&:active,
		&:hover {
			color: $orange;
		}
	}
}

/*------------------------------------
	Twitter handles
------------------------------------*/
.blog-section__twitter-handles {
	border-top: 1px solid $blog-grey;
	flex-wrap: wrap;
	gap: 20px;
	margin-top: 48px;
	padding-top: 36px;
}

/*------------------------------------
	Twitter handle(s)
------------------------------------*/
.blog-section__twitter-handles,
.blog-section__twitter-handle {
	align-items: center;
	display: flex;
	justify-content: center;
}

/*------------------------------------
	Twitter handle
------------------------------------*/
.blog-section__twitter-handle {
	color: $brand_green;
	font-size: 14px;
	gap: 8px;
	position: relative;
	text-transform: uppercase;

	svg {
		height: 1em;
		width: 1em;
	}

	a {
		color: $brand_green;

		&:before {
			content: '';
			inset: 0;
			position: absolute;
		}

		&:active,
		&:hover {
			text-decoration: underline;
		}
	}
}

/*------------------------------------
	Back link
------------------------------------*/
@media (min-width: 768px) {
	.blog-section__back-link {
		display: inline-block;
		margin: 30px 0 20px;

		&:active,
		&:hover {
			color: $orange;
		}
	}
}

/*------------------------------------
	Category posts
------------------------------------*/
.blog-section__category-posts {
	margin-top: 40px;
}
