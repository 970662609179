/* Layout: _blog-signup-form.scss
---------------------------------------------------------------------------------- */
.blog-signup-form {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	justify-content: center;

	label {
		font-size: 25px;
		line-height: (34/31);
		text-align: center;
		width: 100%;
	}

	input[type='email'] {
		border: none;
		border-radius: 0;
		color: $black;
		flex: 1 1 100%;
		font-family: inherit;
		font-size: 17px;
		height: 34px;
		padding: 0 17px;

		&::placeholder {
			opacity: 1;
		}
	}

	button {
		flex-shrink: 0;
		background-image: none;
		margin: 0;
	}
}

@media (min-width: 576px) {
	.blog-signup-form {
		gap: 24px;
		justify-content: flex-start;

		label {
			font-size: 31px;
			text-align: left;
		}

		input[type='email'] {
			flex: 1 1 auto;
		}
	}
}

@media (min-width: 992px) {
	.blog-signup-form {
		label {
			max-width: 381px;
		}
	}
}
