// main: ./style.scss

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin vp_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  display: box;
  box-orient: horizontal;
}

@mixin vp_order($order) {
  -webkit-box-ordinal-group: $order;   /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-ordinal-group: $order;      /* OLD - Firefox 19- */
  -ms-flex-order: $order;              /* TWEENER - IE 10 */
  -webkit-order: $order;               /* NEW - Chrome */
  order: $order;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin box_sizing($type: border)
{
  -webkit-box-sizing: #{$type}-box;
  box-sizing: #{$type}-box;

  *,
  *::before,
  *::after
  {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
  }
  // end wildcard
}

@mixin transform($transforms)
{
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin rotate($deg)
{
    @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale)
{
    @include transform(scale($scale));
}

@mixin translate($x: 0, $y: 0)
{
    @include transform(translate($x, $y));
}

@mixin skew($x, $y)
{
    @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin border_radius($radius: 5px, $important: null)
{
	-webkit-border-radius: $radius $important;
	-moz-border-radius: $radius $important;
	border-radius: $radius $important;
}
