.submission_notification
{
    position: relative;
    z-index: 1;

    padding: 57px 21px 21px 21px;

    background-color: $super_light_grey;
    border: 1px solid $nav_grey;

    text-align: center;

    &::before
    {
        content: '';

        position: absolute;
        z-index: -1;
        top: 21px;
        left: 50%;

        display: block;
        width: 20px;
        height: 20px;
        margin-left: -10px;

        background-image: url('../../images/open-in-new-window.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
    // end .submission_notification::before

    p
    {
        font-size: 15px;
        line-height: 21px;
    }
    // end p
}
// end .submission_notification

    .submission_notification--locked
    {
        &::before
        {
            background-image: url('../../images/lock.svg');
        }
        // end .submission_notification--locked::before
    }
    // end .submission_notification--locked

    .submission_notification--highlight
    {
        p,
		a
        {
            color: $brand_orange;

            font-family: $medium_font;
            font-size: 16px;
        }
        // end p
    }
    // end .submission_notification--highlight
