.sidebar_block
{
    background-color: $brand_green;

    text-align: center;
}

// end .sidebar_block

    .sidebar_block__banner
    {
    }
    // end .sidebar_block__banner

        .sidebar_block__banner_image
        {
        }
        // end .sidebar_block__banner_image

    .sidebar_block__content
    {
        padding: 30px;

        @media screen and (max-width: $site_width)
        {
            padding: 30px 15px;
        }
        // end @max-width $site_width
    }
    // end .sidebar_block__content

        .sidebar_block__title
        {
            margin-bottom: 20px !important;

            color: $white;

            font-family: $medium_font;
            font-size: 22px;
            line-height: 28px;

            span
            {
                display: block;

                font-family: $medium_font;
            }
            // end span

            .landing__landing_sidebar &
            {
                font-family: $light_font;
                font-size: 28px;
                line-height: 34px;
                text-transform: uppercase;

                @media screen and (max-width: 640px)
                {
                    font-size: 24px;
                    line-height: 28px;
                }
                // end @max-width: 640px

                @media screen and (max-width: 512px)
                {
                    font-size: 20px;
                    line-height: 24px;
                }
                // end @max-width: 512px
            }
            // end .landing__landing_sidebar .sidebar_block__title

            @media screen and (max-width: 512px)
            {
                font-size: 18px;
                line-height: 23px;
                line-height: 23px;
            }
            // end @max-width: 512px
        }
        // end .sidebar_block__title

        .sidebar_block__subtitle
        {
            color: $white;

            font-family: $light_font;
            font-size: 17px;
            line-height: 24px;

            @media screen and (max-width: 512px)
            {
                font-size: 16px;
            }
            // end @max-width: 512px
        }
        // end .sidebar_block__subtitle

        .sidebar_block__description
        {
            margin-top: 20px;

            font-size: 14px;
            line-height: 20px;
        }
        // end .sidebar_block__description

        .sidebar_block__call_to_action
        {
            display: block;
            margin-top: 20px;
            margin-bottom: 0;
            padding-top: 17px;
            padding-bottom: 14px;

            text-align: center;
            color: $brand_green;
        }
        // end .sidebar_block__call_to_action

.sidebar_block.sidebar_bg-dark-grey {
    background-color: $dark_grey;

    .sidebar_block__description {
        color: $white;
    }
}

.sidebar_timeline {
    padding: 14px 0;
}