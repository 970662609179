// main: style.scss

$brand_green: #8cbb3d;
$brand_orange: #ec6d0e;
$brand_orange_light: #FDF7F4;
$brand_brown: #7F4E25;

$white: #fff;
$ultra_ultra_light_grey: #D1D1D1;
$ultra_light_grey: #f4f4f4;
$super_light_grey: #f0f0f0;
$lighter_grey: #D8D8D8;
$light_grey: #d7d7d7;
$nav_grey: #CECECE;
$nav_grey_dark: #B6B6B6;
$mid_grey: #838383;
$mid_dark_grey: #585858;
$dark_grey: #494949;
$black: #000;

$bad_red: #b82626;

$grade_a: #95B952;
$grade_b: #B5DC73;
$grade_c: #FFAE71;
$grade_d: $brand_orange;
$grade_w: $light_grey;

$red: #D84040;
$orange: #EC6D0D;

$blog-grey: #E2E2E3;
