// main: ../style.scss

.footer
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    padding-top: 40px;
    padding-bottom: 110px;

    background-color: $dark_grey;
    background-image: url('../../images/Sheep_Goats/Sheep_Goat.svg');
    background-repeat: repeat-x;
    background-position: bottom center;
    background-size: 200px;
}
// end .footer

    .site_width
    {
        .footer &
        {
            width: $site_width;
            margin: 0 auto;

            font-size: 0;
        }
        // end .footer .site_width
    }
    // end .site_width

        .footer__footer_introduction
        {
            margin-bottom: 30px;
            padding-right: 7px;
            padding-left: 7px;

            color: $nav_grey_dark;

            font-family: $light_font;
            font-size: 14px;
            line-height: 18px;
        }
        // end .footer__footer_introduction

        .footer__footer_column
        {
            position: relative;
            z-index: 1;

            display: inline-block;
            vertical-align: top;
            width: (100% / 3);
            padding-right: 15px;
            padding-left: 22px;

            &::before
            {
                content: '';

                position: absolute;
                top: 0;
                left: 7px;

                display: block;
                width: 1px;
                height: 100%;

                background-color: $mid_grey;
            }
            // end .footer__footer_column::before

            h6
            {
                padding-top: 3px;
                padding-bottom: 3px;

                color: $nav_grey;

                font-family: $medium_font;
                font-size: 14px;
            }
            // end h6

            ul
            {
                li
                {
                    padding: 3px 0;

                    a
                    {
                        color: $nav_grey_dark;

                        font-family: $light_font;
                        font-size: 14px;

                        &:hover
                        {
                            text-decoration: underline;
                        }
                        // end a:hover
                    }
                    // end a

                    &:last-child
                    {
                        padding-bottom: 0;
                    }
                    // end li:last-child
                }
                // end li
            }
            // end ul
        }
        // end .footer__footer_column










@media screen and (max-width: $site_width + 14px)
{
    .footer
    {
    }
    // end .footer

        .site_width
        {
            .footer &
            {
                width: 100%;
                padding-right: 7px;
                padding-left: 7px;
            }
            // end .footer .site_width
        }
        // end .site_width
}
// end @media screen and (max-width: $site_width + 14px)










@media screen and (max-width: 768px)
{
    .footer
    {
    }
    // end .footer

        .site_width
        {
            .footer &
            {
            }
            // end .footer .site_width
        }
        // end .site_width

            .footer__footer_column
            {
                width: 50%;
            }
            // end .footer__footer_column
}
// end @media screen and (max-width: 768px)










@media screen and (max-width: 512px)
{
    .footer
    {
    }
    // end .footer

        .site_width
        {
            .footer &
            {
            }
            // end .footer .site_width
        }
        // end .site_width

            .footer__footer_column
            {
                width: 100%;

                & + .footer__footer_column
                {
                    margin-top: 21px;
                }
                // end .footer__footer_column + .footer__footer_column
            }
            // end .footer__footer_column
}
// end @media screen and (max-width: 512px)
