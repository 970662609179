.supporting-documents-panel {
    // padding: 40px;
    background-color: $white;
    border: $light_grey 1px solid;
    margin: 40px 0;
    border-radius: 5px;
    div.compose {
        h3 {
            font-size: 22px;
        }
        border-bottom: $light_grey 1px solid;
        padding: 40px;
    }
    div.documents-container {
        padding: 20px 33px;

        .documents {
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style-type: none;

            & > li {
                width: 50%;
                display: inline-block;
                float: left;
                padding: 10px 10px;

                @media (max-width: 600px) {
                    width: 100%;
                }

                .btn-download {
                    width: 100%;
                    margin: 0;
                    max-height: 51px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    fieldset {
        padding: 0;
        border: 0;
    }
    .action {
        margin: 0;
    }
}