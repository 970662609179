.primary_navigation
{
    background-color: $nav_grey;
    border-bottom: 2px solid $brand_orange;

    text-align: center;

    @include box_sizing();
}
// end .primary_navigation

    #primary_navigation__toggle_input
    {
        position: absolute;

        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;

        overflow: hidden;
        clip: rect(0 0 0 0);
    }
    // end .primary_navigation__toggle_input

    .primary_navigation__toggle_label
    {
        display: none;

        padding: 13px 20px 8px 20px;

        font-family: $medium_font;
        font-size: 15px;
        line-height: 20px;
        text-align: center;

        #primary_navigation__toggle_input:checked + &
        {
            background-color: $nav_grey_dark;
        }
        // end #primary_navigation__toggle_input:checked + .primary_navigation__toggle_label

        @media screen and (max-width: 640px)
        {
            display: block;
        }
        // end max-width: 640px
    }
    // end .primary_navigation__toggle_label

    .primary_navigation__menu
    {
        display: inline-table;
        width: 100%;
        max-width: 1000px;

        @media screen and (max-width: 640px)
        {
            display: none;
            padding-top: 5px;
            padding-bottom: 5px;

            background-color: $light_grey;

            #primary_navigation__toggle_input:checked ~ &
            {
                display: block;
            }
            // end #primary_navigation__toggle_input:checked ~ .primary_navigation__menu
        }
        // end max-width: 640px
    }
    // end .primary_navigation__menu

        .primary_navigation__menu_item
        {
            position: relative;
            z-index: 1;

            display: table-cell;

            font-size: 0;

            &::before
            {
                content: '';

                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }
            // end .primary_navigation__menu_item::before

            @media screen and (max-width: 640px)
            {
                display: block;
            }
            // end max-width: 640px
        }
        // end .primary_navigation__menu_item

            .primary_navigation__menu_item_link
            {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                padding: 13px 20px 8px 20px;

                font-family: $medium_font;
                font-size: 15px;
                line-height: 20px;
                text-align: center;

                &::before,
                &::after
                {
                    content: '';

                    position: absolute;
                    bottom: 0;

                    display: block;
                    width: 1px;
                    height: 8px;

                    background-color: $brand_orange;
                }
                // end .primary_navigation__menu_item_link::before
                // end .primary_navigation__menu_item_link::after

                &::before
                {
                    left: 0;

                    .primary_navigation__menu_item:first-child &
                    {
                        display: none;
                    }
                    // end .primary_navigation__menu_item:first-child .primary_navigation__menu_item_link::before
                }
                // end .primary_navigation__menu_item_link::before

                &::after
                {
                    right: 0;

                    .primary_navigation__menu_item:last-child &
                    {
                        display: none;
                    }
                    // end .primary_navigation__menu_item:last-child .primary_navigation__menu_item_link::after
                }
                // end .primary_navigation__menu_item_link::after

                &:hover
                {
                    background-color: $nav_grey_dark;
                }
                // end .primary_navigation__menu_item_link:hover

                @media screen and (max-width: 860px)
                {
                    padding-right: 10px;
                    padding-left: 10px;
                }
                // end max-width: 860px

                @media screen and (max-width: 704px)
                {
                    padding-right: 5px;
                    padding-left: 5px;
                }
                // end max-width: 704px

                @media screen and (max-width: 640px)
                {
                    padding-top: 8px;
                    padding-bottom: 6px;

                    &::before,
                    &::after
                    {
                        display: none;
                    }
                    // end .primary_navigation__menu_item_link::before
                    // end .primary_navigation__menu_item_link::after

                    &:hover
                    {
                        background-color: $nav_grey;
                    }
                    // end .primary_navigation__menu_item_link:hover
                }
                // end max-width: 640px
            }
            // end .primary_navigation__menu_item_link