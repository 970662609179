// main: ../style.scss

.footer-disclaimer
{
    max-width: 500px;
    margin: 0 auto 30px auto;
    padding: 0 20px;

    font-size: 13px;
    font-style: italic;
    line-height: 16px;
    text-align: center;
}
// end .footer-disclaimer

.partner-strip
{
    min-height: 180px;

    background-color: $white;

    text-align: center;

    h5
    {
        display: inline-block;
        vertical-align: middle;
        margin-top: 30px;
    }
    // end h5
}
// end .partner-strip

    .site_width
    {
        .partner-strip &
        {
            width: $site_width;
            margin: 0 auto;
        }
        // end .partner-strip .site_width
    }
    // end .site_width

        .logo-container
        {
            display: block;

            font-size: 0;
        }
        // end .logo-container

            .logo-item
            {
                display: inline-block;
                vertical-align: middle;
                width: (100% / 6);
                padding: 20px;

                a
                {
                    display: inline-block;
                    vertical-align: middle;

                    img
                    {
                        display: block;
                        height: 80px;
                    }
                    // end img
                }
                // end a
            }
            // end .logo-item










@media screen and (max-width: $site_width)
{
    .partner-strip
    {
    }
    // end .partner-strip

        .site_width
        {
            .partner-strip &
            {
                width: 100%;
            }
            // end .partner-strip .site_width
        }
        // end .site_width

            .logo-container
            {
                padding-top: 21px;
                padding-bottom: 21px;
            }
            // end .logo-container

                .logo-item
                {
                    margin: 0 (100% / 12);
                    padding-right: 0;
                    padding-left: 0;

                    a
                    {
                        img
                        {
                            height: 60px;
                        }
                        // end img
                    }
                    // end a
                }
                // end .logo-item
}
// end @media screen and (max-width: $site_width)










@media screen and (max-width: 896px)
{
    .partner-strip
    {
    }
    // end .partner-strip

        .site_width
        {
            .partner-strip &
            {
            }
            // end .partner-strip .site_width
        }
        // end .site_width

            .logo-container
            {
            }
            // end .logo-container

                .logo-item
                {
                    width: 25%;
                    margin-right: (100% / 24);
                    margin-left: (100% / 24);
                }
                // end .logo-item
}
// end @media screen and (max-width: 896px)










@media screen and (max-width: 512px)
{
    .partner-strip
    {
    }
    // end .partner-strip

        .site_width
        {
            .partner-strip &
            {
            }
            // end .partner-strip .site_width
        }
        // end .site_width

            .logo-container
            {
            }
            // end .logo-container

                .logo-item
                {
                    width: (100% / 12) * 5;
                    padding-right: 21px;
                    padding-left: 21px;
                }
                // end .logo-item
}
// end @media screen and (max-width: 512px)










@media screen and (max-width: 384px)
{
    .partner-strip
    {
    }
    // end .partner-strip

        .site_width
        {
            .partner-strip &
            {
            }
            // end .partner-strip .site_width
        }
        // end .site_width

            .logo-container
            {
            }
            // end .logo-container

                .logo-item
                {
                    padding-right: 0;
                    padding-left: 0;
                }
                // end .logo-item
}
// end @media screen and (max-width: 384px)
