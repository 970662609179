/* Layout: _blog-media-contact.scss
---------------------------------------------------------------------------------- */
.blog-media-contact {
	p {
		font-size: 16px;
		margin-bottom: 1.5em;
	}

	a {
		color: $brand_green;

		&:link,
		&:visited {
			text-decoration: underline;
		}

		&:active,
		&:hover {
			text-decoration: none;
		}
	}
}
