.solver-application-modal {
	position: fixed;
	z-index: 999;
	visibility: hidden;
	opacity: 0;
	top: 50%;
	left: 50%;
	width: 90%;
	max-width: 820px;
	border: 5px solid $brand_orange;
	background: $white;
	transform: translate(-50%, -50%);
	transition: all .2s ease-in-out;

	&.visible {
		opacity: 1;
		visibility: visible;

		& ~ .solver-application-modal__cover {
			opacity: 1;
			visibility: visible;
		}
	}
}

.solver-application-modal__cover {
	position: fixed;
	z-index: 998;
	background-color: rgba($black, .5);
	opacity: 0;
	visibility: hidden;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	transition: all .2s ease-in-out;
}

.solver-application-modal__close {
	position: absolute;
	right: 0;
	bottom: calc(100% + 10px);
	cursor: pointer;
	user-select: none;
	color: $white;
}

.solver-application-modal__header {
	background-color: $brand_orange;

	h2 {
		display: flex;
		align-items: center;
		color: $white;
		margin: 0;
		padding: 10px 20px;
		line-height: 1.2;
		font-size: 20px;
		font-weight: bold;
		text-align: left;
		max-width: unset;

		&::before {
			width: 38px;
			height: 38px;
			flex-shrink: 0;
			margin-right: 15px;
			background: url('../../images/warning-icon.svg') 50% / contain no-repeat;
			content: "";
		}
	}
}

.solver-application-modal__body {
	padding: 30px 35px;
	background-color: $ultra_light_grey;

	p:not(:last-child) {
		margin: 0 0 .5rem;
	}
}

.solver-application-modal__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px 35px;

	.action {
		margin: 0;
	}
}

.solver-application-modal__error {
	display: block;
	width: 100%;
	margin: 20px 0;
	color: $white;
	padding: 10px 15px;
	border: 1px solid $bad_red;
	background-color: rgba($bad_red, .8);
}

.solver-application-modal__close-button {
	background-image: url('../../images/Arrow Left.svg');
}
