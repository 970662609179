// main: ../style.scss

.header
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    background-color: $white;
    border-top: 2px solid $brand_green;

    font-size: 0;

    .site_width
    {
        width: $site_width;
        margin: 0 auto;

        font-size: 0;

        @media screen and (max-width: $site_width + 14px)
        {
            width: 100%;
        }
        // end @media screen and (max-width: $site_width + 14px)
    }
    // end .site_width
}
// end .header

    .header__header_logo
    {
        display: inline-block;
        vertical-align: middle;
        width: (100% / 3);
        padding: 35px 7px;

        text-align: center;

        img
        {
            width: auto;
            max-height: 70px;
            margin-bottom: 20px;
        }
        // end img

        p
        {
            font-size: 15px;
            line-height: 19px;
        }
        // end p

        @media screen and (max-width: $site_width + 14px)
        {
            padding-right: 14px;
            padding-left: 14px;
        }
        // end @media screen and (max-width: $site_width + 14px)

        @media screen and (max-width: 768px)
        {
            width: 50%;
            padding-right: 10%;
            padding-left: 10%;

            &:first-of-type
            {
                padding-right: 10px;
            }
            // end .header__header_logo:first-of-type

            &:last-of-type
            {
                padding-left: 10px;
            }
            // end .header__header_logo:last-of-type
        }
        // end @max-width: 768px

        @media screen and (max-width: 640px)
        {
            padding-right: 5%;
            padding-left: 5%;
        }
        // end @max-width: 640px

        @media screen and (max-width: 512px)
        {
            width: 100%;

            &:first-of-type
            {
                padding-right: 5%;
            }
            // end .header__header_logo:first-of-type

            &:last-of-type
            {
                padding-top: 0;
                padding-left: 5%;
            }
            // end .header__header_logo:last-of-type
        }
        // end @max-width: 512px
    }
    // end .header__header_logo

    .header__header_buttons
    {
        display: inline-block;
        vertical-align: middle;
        width: 25%;
        margin-left: (100% / 3) - 25%;
        padding: 35px 7px;

        .btn
        {
            width: 100%;
            margin: 8px 0;
            padding-left: 60px;

            @media screen and (max-width: 896px)
            {
                padding-left: 50px;
            }
            // end @max-width: 896px

            @media screen and (max-width: 768px)
            {
                width: 48%;

                background-position: 15px center;

                &:first-child
                {
                    margin-right: 4%;
                }
                // end .btn:first-child
            }
            // end @max-width: 768px

            @media screen and (max-width: 384px)
            {
                width: 100%;

                &:first-child
                {
                    margin-right: 0;
                }
                // end .btn:first-child
            }
            // end @max-width: 384px
        }
        // end .btn

        @media screen and (max-width: $site_width + 14px)
        {
            padding-right: 14px;
            padding-left: 14px;
        }
        // end @media screen and (max-width: $site_width + 14px)

        @media screen and (max-width: 768px)
        {
            width: 100%;
            margin-left: 0;
            padding-top: 0;
            padding-right: 10%;
            padding-left: 10%;
        }
        // end @max-width: 768px

        @media screen and (max-width: 640px)
        {
            padding-right: 5%;
            padding-left: 5%;
        }
        // end @max-width: 640px
    }
    // end .header__header_buttons

.loggedin-bar
{
    padding: 12px 0;

    background-color: $light_grey;

    font-size: 14px;
    text-align: right;

    .site_width
    {
        width: $site_width;
        margin: 0 auto;
        padding-right: 7px;
        padding-left: 7px;

        @media screen and (max-width: $site_width + 14px)
        {
            width: 100%;
            padding-right: 14px;
            padding-left: 14px;
        }
        // @media screen and (max-width: $site_width + 14px)
    }
    // end .site_width
}
// end .loggedin-bar

/*
header {
    // height: 214px;
    min-height: 214px;
    width: 100%;
    background-color: $white;
    border-top: 2px solid $brand_green;
    display: flex;
    flex-wrap: wrap;
    section {
        // width: 50%;
        // height: 214px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        flex-grow: 1;
        .logo-container {
            margin: 10px 35px;
            img {
                max-height: 70px;
                width: auto;
                margin: 0 20px 20px 20px;
            }
            p {
                font-size: 15px;
            }
        }
        &.left {
            justify-content: flex-end;
            @media (max-width: 850px) {
                justify-content: center;
            }
        }
        &.right {
            @media (max-width: 850px) {
                justify-content: center;
            }
        }
        .buttons {
            margin: 10px 35px;
            a {
                margin: 5px auto;
                font-size: 15px;
                padding-top: 17px;
                padding-bottom: 13px;
                width: 80%;
            }
        }
    }
}
*/
