/* Layout: _blog-posts.scss
---------------------------------------------------------------------------------- */
/*------------------------------------
	Grid
------------------------------------*/
ul.blog-posts__grid {
	display: grid;
	gap: 20px;
	list-style-type: none;
	margin: 0;
}

@media (min-width: 768px) {
	ul.blog-posts__grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 992px) {
	ul.blog-posts__grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

/*------------------------------------
	Full width item (Featured post)
------------------------------------*/
@media (min-width: 768px) {
	.blog-posts__full-width-item {
		grid-column: 1 / 3;
	}
}

@media (min-width: 992px) {
	.blog-posts__full-width-item {
		grid-column: 1 / 4;
	}
}
