/* Layout: _blog-socials.scss
---------------------------------------------------------------------------------- */
.blog-socials {
	margin: 40px auto 0;
	max-width: 775px;
	padding: 0 20px;

	p {
		font-size: 18px;
		margin-bottom: 1.33em;
		text-align: center;
	}
}

/*------------------------------------
	Grid
------------------------------------*/
.blog-socials__grid {
	display: grid;
	gap: 11px;
}

@media (min-width: 768px) {
	.blog-socials__grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*------------------------------------
	Card
------------------------------------*/
.blog-socials__card {
	background-color: $white;
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, 1fr);
	padding: 20px 15px;
}

@media (min-width: 576px) {
	.blog-socials__card {
		padding: 20px 25px;
	}
}

/*------------------------------------
	Card url
------------------------------------*/
.blog-socials__card-url {
	a {
		color: $orange;
		font-size: 18px;

		&:active,
		&:hover {
			text-decoration: underline;
		}
	}
}

/*------------------------------------
	Card social
------------------------------------*/
.blog-socials__card-social {
	align-items: center;
	display: grid;
	gap: 12px;
	grid-column: 2/3;
	grid-template-columns: 20px 1fr;

	a {
		text-decoration: underline;

		&:active,
		&:hover {
			text-decoration: none;
		}
	}
}
