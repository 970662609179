.accordion
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
// end .accordion

    .accordion__accordion_item
    {
        margin-bottom: 7px;

        border: 1px solid $light_grey;

        &:last-child
        {
            margin-bottom: 0;
        }
        // end .accordion__accordion_item:last-child
    }
    // end .accordion__accordion_item

        .accordion_item__toggle
        {
            position: absolute;

            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;

            overflow: hidden;
            clip: rect(0 0 0 0);

            &:checked
            {
                & ~ .accordion_item__title
                {
                    &::after
                    {
                        content: '-';
                    }
                    // end .accordion_item__toggle:checked ~ .accordion_item__title::after

                    &:hover
                    {
                        background-color: $light_grey;
                    }
                    // end .accordion_item__title:hover
                }
                // end .accordion_item__toggle:checked ~ .accordion_item__title

                & ~ .accordion_item__description
                {
                    display: block;
                }
                // end .accordion_item__toggle:checked ~ .accordion_item__description
            }
            // end .accordion_item__toggle:checked
        }
        // end .accordion_item__toggle

        .accordion_item__title
        {
            position: relative;
            z-index: 1;

            display: block;
            padding: 11px 54px 9px 25px;

            background-color: $white;

            font-family: $light_font;
            font-size: 18px;
            line-height: 24px;

            span
            {
                font-family: $medium_font;
            }
            // end span

            &::before,
            &::after
            {
                content: '';

                position: absolute;
                top: 50%;
                right: 22px;

                display: block;

                @include border_radius(50%);
            }
            // end .accordion_item__title::before
            // end .accordion_item__title::after

            &::before
            {
                width: 26px;
                height: 26px;
                margin-top: -13px;
                margin-right: -13px;

                background-color: $white;
                border: 2px solid $brand_green;
            }
            // end .accordion_item__title::before

            &::after
            {
                content: '+';

                width: 18px;
                height: 18px;
                margin-top: -9px;
                margin-right: -9px;
                padding-left: 1px;

                background-color: $brand_green;
                color: $white;

                font-size: 20px;
                line-height: 18px;
                text-align: center;
            }
            // end .accordion_item__title::after

            &:hover
            {
                cursor: pointer;
                background-color: $ultra_light_grey;
            }
            // end .accordion_item__title:hover
        }
        // end .accordion_item__title

        .accordion_item__description
        {
            display: none;
            padding: 25px;

            background-color: $ultra_light_grey;
        }
        // end .accordion_item__description