.panel--judges
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
// end .panel--judges

    .site_width
    {
        .panel--judges &
        {
            width: $site_width;
            margin: 0 auto;
            padding-right: 7px;
            padding-left: 7px;

            @media screen and (max-width: $site_width + 14px)
            {
                width: 100%;
                padding-right: 14px;
                padding-left: 14px;
            }
            // @media screen and (max-width: $site_width)
        }
        // end .panel--judges .site_width
    }
    // end .site_width

        .judges__judges_title
        {
            max-width: none;
            margin: 0 0 10px;
            padding: 15px 25px;

            background-color: $mid_grey;
            color: $white;

            font-family: $medium_font;
            font-size: 30px;
            line-height: 36px;
            text-transform: uppercase;
        }
        // end .judges__judges_title

        .judges__judges_subtitle
        {
            max-width: none;
            margin-top: 15px !important;
            margin-bottom: 0 !important;
            padding: 15px 10px;

            font-family: $light_font;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
        }
        // end .judges__judges_subtitle

        .judges__judges_introduction
        {
            margin-top: 15px;
            padding: 20px 25px 30px 25px;

            background-color: $white;
        }
        // end .judges__judges_introduction

            .judges_introduction__title
            {
                font-family: $medium_font;
                font-size: 30px;
                line-height: 36px;
            }
            // end .judges_introduction__title

            .judges_introduction__content
            {
                .judges_introduction__title + &
                {
                    margin-top: 15px;
                }
                // end .judges_introduction__title + .judges_introduction__content
            }
            // end .judges_introduction__content

        .dataTables_filter,
        .dataTables_length {
            margin-bottom: 15px;
        }
        .judges__judges_submissions
        {
            margin-top: 15px;

            @media screen and (max-width: $site_width + 14px)
            {
                overflow-x: scroll;
                -webkit-overflow-scrolling: touch;
            }
            // end @media screen and (max-width: $site_width + 14px)
        }
        // end .judges__judges_submissions

            .judges_submissions__table
            {
                width: 100%;

                border-collapse: collapse !important;

                @media screen and (max-width: $site_width + 14px)
                {
                    width: $site_width - 14px;
                }
                // end @media screen and (max-width: $site_width - 14px)
            }
            // end .judges_submissions__table

                .judges_submissions__table_header
                {
                    th,
                    td
                    {
                        vertical-align: middle;
                        padding: 20px 15px;

                        border-left: 1px solid $white;

                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;

                        &:first-child
                        {
                            border-left: none;
                        }
                        // end th:first-child
                        // end td:first-child
                    }
                    // end th
                    // end td

                    th
                    {
                        background-color: $brand_orange;
                        color: $white;
                        border-bottom: 1px solid $white;

                        font-family: $medium_font;
                    }
                    // end th

                    td
                    {
                        background-color: $nav_grey;

                        &:first-child
                        {
                            text-align: left;
                        }
                        // end td:first-child
                    }
                    // end td
                }
                // end .judges_submissions__table_header

                .judges_submissions__table_content
                {
                    th,
                    td
                    {
                        vertical-align: middle;
                        padding: 10px 15px;

                        background-color: $white;

                        font-size: 15px;
                        line-height: 20px;
                        text-align: center;
                    }
                    // end th
                    // end td

                    th
                    {
                        font-family: $medium_font;
                        text-align: left;
                        text-transform: uppercase;
                    }
                    // end th

                    td
                    {
                        border-left: 1px solid $nav_grey;

                        a
                        {
                            display: inline-block;

                            .iconography
                            {
                                position: relative;

                                display: inline-block;
                                width: 40px;
                                height: 40px;

                                img
                                {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;

                                    max-width: 50%;
                                    max-height: 50%;

                                    @include translate(-50%, -50%);
                                }
                                // end img
                            }
                            // end .iconography
                        }
                        // end a

                        &:first-child
                        {
                            border-left: none;

                            text-align: left;
                        }
                        // end td:first-child
                    }
                    // end td
                }
                // end .judges_submissions__table_content

                    .judges_submissions__table_content_row
                    {
                        .judges_submissions__table_content &
                        {
                            border-bottom: 1px dashed $nav_grey;

                            &.judges_submissions__table_content_row--under_review
                            {
                                th,
                                td
                                {
                                    background-color: $brand_orange_light;
                                }
                                // end th
                                // end td
                            }
                            // end .judges_submissions__table_content .judges_submissions__table_content_row.judges_submissions__table_content_row--under_review
                        }
                        // end .judges_submissions__table_content .judges_submissions__table_content_row
                    }
                    // end .judges_submissions__table_content_row

        .judges__judges_submission
        {
            font-size: 0;
        }
        // end .judges__judges_submission

            .judges__judges_milestone
            {
                margin-top: 21px;
            }
            // end .judges__judges_milestone

                .judges_milestone__toggler
                {
                    position: absolute;

                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;

                    overflow: hidden;
                    clip: rect(0 0 0 0);

                    &:checked
                    {
                        & + .judges_milestone__toggle
                        {
                            .judges_milestone__toggle_label
                            {
                                &::after
                                {
                                    background-position: 11px 15px;
                                    @include rotate(180);
                                }
                                // end .judges_milestone__toggle_label::after
                            }
                            // end .judges_milestone__toggle_label
                        }
                        // end .judges_milestone__toggler:checked + .judges_milestone__toggle

                        & ~ .judges_milestone__content
                        {
                            display: block;
                        }
                        // end .judges_milestone__toggler:checked ~ .judges_milestone__content
                    }
                    // end .judges_milestone__toggler:checked
                }
                // end .judges_milestone__toggler

                .judges_milestone__toggle
                {
                    display: block;
                    width: 100%;
                    padding: 7px 0;

                    background-color: $mid_grey;
                    color: $white;
                    border: none;

                    font-size: 0;

                    @include transition(background, 200ms);

                    &:hover
                    {
                        background-color: whiten($mid_grey, 10%);
                        cursor: pointer;
                    }
                    // end .judges_milestone__toggle:hover
                }
                // end .judges_milestone__toggle

                    .judges_milestone__toggle_details
                    {
                        display: inline-block;
                        vertical-align: middle;
                        width: (100% / 12) * 7;
                    }
                    // end .judges_milestone__toggle_details

                        .judges_milestone__toggle_title
                        {
                            display: inline-block;
                            vertical-align: middle;
                            width: (100% / 7) * 3;
                            padding: 8px 21px;

                            font-size: 21px;
                            font-weight: 700;
                            line-height: 24px;
                            text-align: left;
                        }
                        // end .judges_milestone__toggle_title

                        .judges_milestone__toggle_status
                        {
                            display: inline-block;
                            vertical-align: middle;
                            width: (100% / 7) * 4;
                            padding-right: 21px;
                            padding-left: 21px;

                            text-align: left;

                            &[data-grade]
                            {
                                position: relative;
                                z-index: 1;

                                padding-left: 68px;

                                &::before
                                {
                                    content: attr(data-grade);

                                    position: absolute;
                                    z-index: -1;
                                    top: 50%;
                                    left: 21px;

                                    display: block;
                                    width: 40px;
                                    height: 40px;
                                    margin-top: -20px;

                                    @include border_radius(20px);

                                    font-family: $medium_font;
                                    font-size: 15px;
                                    line-height: 40px;
                                    text-align: center;
                                }
                                // end .judges_milestone__toggle_status[data-grade]::before

                                &::after
                                {
                                    content: '';

                                    display: inline-block;
                                    vertical-align: middle;
                                    height: 40px;
                                }
                                // end .judges_milestone__toggle_status[data-grade]::after
                            }
                            // end .judges_milestone__toggle_status[data-grade]

                            &[data-grade="A"]
                            {
                                &::before
                                {
                                    background-color: $grade_a;
                                }
                                // end .judges_milestone__toggle_status[data-grade="A"]::before
                            }
                            // end .judges_milestone__toggle_status[data-grade="A"]

                            &[data-grade="B"]
                            {
                                &::before
                                {
                                    background-color: $grade_b;
                                }
                                // end .judges_milestone__toggle_status[data-grade="B"]::before
                            }
                            // end .judges_milestone__toggle_status[data-grade="B"]

                            &[data-grade="C"]
                            {
                                &::before
                                {
                                    background-color: $grade_c;
                                }
                                // end .judges_milestone__toggle_status[data-grade="C"]::before
                            }
                            // end .judges_milestone__toggle_status[data-grade="C"]

                            &[data-grade="D"]
                            {
                                &::before
                                {
                                    background-color: $grade_d;
                                }
                                // end .judges_milestone__toggle_status[data-grade="D"]::before
                            }
                            // end .judges_milestone__toggle_status[data-grade="D"]
                        }
                        // end .judges_milestone__toggle_status

                            .judges_milestone__toggle_status_caption
                            {
                                display: inline-block;
                                vertical-align: middle;

                                font-size: 15px;
                                line-height: 18px;
                            }
                            // end .judges_milestone__toggle_status_caption

                    .judges_milestone__toggle_label
                    {
                        position: relative;
                        z-index: 1;

                        display: inline-block;
                        vertical-align: middle;
                        width: (100% / 12) * 5;
                        padding-right: 70px;
                        padding-left: 21px;

                        font-size: 15px;
                        font-weight: 700;
                        line-height: 18px;
                        text-align: right;

                        &::after
                        {
                            content: '';

                            position: absolute;
                            z-index: -1;
                            top: 50%;
                            right: 20px;

                            display: block;
                            width: 40px;
                            height: 40px;
                            margin-top: -20px;

                            background-color: $white;
                            background-image: url('../../images/arrow.svg');
                            background-repeat: no-repeat;
                            background-position: 12px 15px;
                            background-size: 18px auto;
                            @include border_radius(20px);
                        }
                        // end .judges_milestone__toggle_label
                    }
                    // end .judges_milestone__toggle_label

                .judges_milestone__content
                {
                    display: none;
                    padding: 20px;

                    background-color: $white;
                }
                // end .judges_milestone__content

                .judges_milestone__content .btn-judge-submission {
                    display: inline-block;
                    margin: 7px;
                    width: 48%; // Old Browsers
                    width: calc(50% - 14px);
                }

            .judges__judges_milestone--has_children {
                > .judges_milestone__toggle
                {
                    background-color: $brand_green;

                    &:hover
                    {
                        background-color: whiten($brand_green, 10%);
                    }
                }

                > .judges_milestone__content > .judges__judges_milestone
                {
                    border: 4px solid $mid_grey;
                    border-top: none;
                }
            }








@media screen and (max-width: 1024px)
{
    .panel--judges
    {
    }
    // end .panel--judges

        .site_width
        {
            .panel--judges &
            {
            }
            // end .panel--judges .site_width
        }
        // end .site_width

            .judges__judges_title
            {
                font-size: 27px;
                line-height: 33px;
            }
            // end .judges__judges_title

            .judges__judges_subtitle
            {
                font-size: 27px;
                line-height: 33px;
            }
            // end .judges__judges_subtitle

            .judges__judges_introduction
            {
            }
            // end .judges__judges_introduction

                .judges_introduction__title
                {
                    font-size: 27px;
                    line-height: 33px;
                }
                // end .judges_introduction__title

        .judges_milestone__content .btn-judge-submission {
            display: block;
            margin: 0;
            width: 100%;
        }
}
// end @media screen and (max-width: 1024px)










@media screen and (max-width: 896px)
{
    .panel--judges
    {
    }
    // end .panel--judges

        .site_width
        {
            .panel--judges &
            {
            }
            // end .panel--judges .site_width
        }
        // end .site_width

            .judges__judges_title
            {
                font-size: 24px;
                line-height: 30px;
            }
            // end .judges__judges_title

            .judges__judges_subtitle
            {
                font-size: 24px;
                line-height: 30px;
            }
            // end .judges__judges_subtitle

            .judges__judges_introduction
            {
            }
            // end .judges__judges_introduction

                .judges_introduction__title
                {
                    font-size: 24px;
                    line-height: 30px;
                }
                // end .judges_introduction__title

            .judges__judges_submission
            {
            }
            // end .judges__judges_submission

                .judges__judges_milestone
                {
                }
                // end .judges__judges_milestone

                    .judges_milestone__toggle
                    {
                    }
                    // end .judges_milestone__toggle

                        .judges_milestone__toggle_details
                        {
                            width: 50%;
                            padding-bottom: 7px;
                        }
                        // end .judges_milestone__toggle_details

                            .judges_milestone__toggle_title
                            {
                                width: 100%;
                            }
                            // end .judges_milestone__toggle_title

                            .judges_milestone__toggle_status
                            {
                                width: 100%;
                            }
                            // end .judges_milestone__toggle_status

                        .judges_milestone__toggle_label
                        {
                            width: 50%;
                        }
                        // end .judges_milestone__toggle_label
}
// end @media screen and (max-width: 896px)










@media screen and (max-width: 768px)
{
    .panel--judges
    {
    }
    // end .panel--judges

        .site_width
        {
            .panel--judges &
            {
            }
            // end .panel--judges .site_width
        }
        // end .site_width

            .judges__judges_title
            {
                padding-right: 14px;
                padding-left: 14px;

                font-size: 21px;
                line-height: 27px;
            }
            // end .judges__judges_title

            .judges__judges_subtitle
            {
                padding-top: 7px;
                padding-bottom: 7px;

                font-size: 21px;
                line-height: 27px;
            }
            // end .judges__judges_subtitle

            .judges__judges_introduction
            {
                padding: 21px 14px;
            }
            // end .judges__judges_introduction

                .judges_introduction__title
                {
                    font-size: 21px;
                    line-height: 27px;
                }
                // end .judges_introduction__title
}
// end @media screen and (max-width: 768px)










@media screen and (max-width: 512px)
{
    .panel--judges
    {
    }
    // end .panel--judges

        .site_width
        {
            .panel--judges &
            {
            }
            // end .panel--judges .site_width
        }
        // end .site_width

            .judges__judges_submission
            {
            }
            // end .judges__judges_submission

                .judges__judges_milestone
                {
                }
                // end .judges__judges_milestone

                    .judges_milestone__toggle
                    {
                        position: relative;
                        z-index: 1;
                    }
                    // end .judges_milestone__toggle

                        .judges_milestone__toggle_details
                        {
                            width: 100%;
                            padding-right: 70px;
                        }
                        // end .judges_milestone__toggle_details

                        .judges_milestone__toggle_label
                        {
                            position: static;

                            width: 100%;
                            padding-top: 7px;
                            padding-bottom: 14px;

                            text-align: left;
                        }
                        // end .judges_milestone__toggle_label
}
// end @media screen and (max-width: 512px)
