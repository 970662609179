/* Layout: _blog-post.scss
---------------------------------------------------------------------------------- */
/*------------------------------------
	Feature image
------------------------------------*/
.blog-post__featured-image {
	margin: -1px 0;

	img {
		display: block;
		width: 100%;
	}
}

/*------------------------------------
	Title
------------------------------------*/
h1.blog-post__title {
	font-size: 28px !important;
	line-height: (34/28) !important;
	margin: 27px 0 .85em !important;
	max-width: none;
}

/*------------------------------------
	Date
------------------------------------*/
.blog-post__date {
	color: $orange;
	display: block;
	font-size: 14px;
}

/*------------------------------------
	Content
------------------------------------*/
.blog-post__content {
	h2 {
		color: $brand-green;
		font-family: $medium_font !important;
		font-size: 16px;
		line-height: 1.5;
		margin: 0 0 2em;
		max-width: none;
		text-align: left;
	}

	p {
		line-height: 1.5;
		margin-bottom: 2em;
	}

	*:last-child {
		margin-bottom: 0;
	}

	img {
		height: auto;
	}

	img.leftAlone {
		width: 100%;
	}

	iframe {
		aspect-ratio: 648/365;
		height: auto;
		width: 100%;
	}
}

/*------------------------------------
	Sidebar
------------------------------------*/
.blog-post__sidebar {
	background-color: $white;
	border: 1px $blog-grey solid;
	padding: 52px 32px;

	*:last-child {
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.blog-post__sidebar {
		margin: 0 15px;
	}
}

/*------------------------------------
	Small heading
------------------------------------*/
h2.blog-post__small-heading {
	color: $brand-green;
	font-family: $medium_font;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 1em;
	max-width: none;
	text-align: left;
	text-transform: uppercase;

	&--black {
		color: $black;
	}

	&--center {
		text-align: center;
	}
}

/*------------------------------------
	Link list
------------------------------------*/
.blog-post__link-list {
	margin-bottom: 2em;

	li:not(:last-child) {
		margin-bottom: 1.5em;
	}

	a {
		cursor: pointer;
		line-height: 1.5;

		&:active,
		&:hover {
			text-decoration: underline;
		}
	}
}

/*------------------------------------
	Navigation
------------------------------------*/
.blog-post__navigation {
	border-top: 1px $blog-grey solid;
	box-sizing: border-box;
	display: flex;
	gap: 20px;
	justify-content: space-between;
	margin-top: 40px;
	padding-top: 64px;
}

@media (min-width: 992px) {
	.blog-post__navigation {
		margin-bottom: 24px;
	}
}

/*------------------------------------
	Navigation item
------------------------------------*/
.blog-post__navigation-item {
	align-items: flex-start;
	display: flex;
	gap: 10px;
	line-height: 1.5;
	max-width: 278px;

	&:active,
	&:hover {
		span {
			color: $orange;
		}
	}

	&--right {
		margin: 0 0 0 auto;
	}
}

/*------------------------------------
	Related posts
------------------------------------*/
.blog-post__related-posts {
	padding: 40px 20px;
}

@media (min-width: #{$site_width}) {
	.blog-post__related-posts {
		padding: 40px 0;
	}
}
