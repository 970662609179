// main: style.scss

@font-face {
    font-family: 'DINMedium';
    src: url('../../fonts/DINMedium/DINMedium.eot');
    src: local('DINMedium'),
    url('../../fonts/DINMedium/DINMedium.woff') format('woff'),
    url('../../fonts/DINMedium/DINMedium.ttf') format('truetype');
}

@font-face {
    font-family: 'DINProLight';
    src: url('../../fonts/DINProLight/DINProLight.eot');
    src: local('DINProLight'),
    url('../../fonts/DINProLight/DINProLight.woff') format('woff'),
    url('../../fonts/DINProLight/DINProLight.ttf') format('truetype');
}

$medium_font: 'DINMedium', sans-serif;
$light_font: 'DINProLight', sans-serif;

$default-font: $light_font;

body {
    font-family: $default-font;
}

h1 {
    font-family: $default-font;
    font-size: 50px;
    line-height: 55px;
    margin: 20px 0;
    text-transform: capitalize;
    max-width: 500px;
}
h2 {
    font-family: $default-font;
    font-size: 41px;
    line-height: 47px;
    margin: 20px 0;
    text-align: center;
    text-transform: capitalize;
    max-width: 500px;
}

h3 {
    font-family: $medium-font;
}

h4 {
    font-family: $default-font;
    font-size: 18px;
    line-height: 25px;
}

h5 {
    font-family: $medium-font;
    font-size: 16px;
}

p {
    font-family: $default-font;
    font-size: 18px;
    line-height: 28px;
}

b {
    font-family: $default-font;
    font-weight: bold;
}

span {
    font-family: $default-font;
}
