.panel--account
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
// end .panel--account

    .site_width
    {
        .panel--account &
        {
            width: $site_width;
            margin: 0 auto;

            font-size: 0;

            @media screen and (max-width: $site_width + 14px)
            {
                width: 100%;
                padding-right: 7px;
                padding-left: 7px;
            }
            // end @media screen and (max-width: $site_width + 14px)
        }
        // end .panel--account .site_width
    }
    // end .site_width

        .account__account_header
        {
            margin-bottom: 21px;
            padding-right: 7px;
            padding-left: 7px;
        }
        // end .account__account_header

            .account__account_title
            {
                max-width: none;
                margin: 0;
                padding: 15px 25px;

                background-color: $mid_grey;
                color: $white;

                font-family: $medium_font;
                font-size: 30px;
                line-height: 36px;
                text-transform: uppercase;
            }
            // end .account__account_title

        .account__account_sidebar
        {
            display: inline-block;
            vertical-align: top;
            width: 25%;
            padding-right: 7px;
            padding-left: 7px;
        }
        // end .account__account_sidebar

            .account__account_navigation
            {
                .btn
                {
                    width: 100%;
                    margin-top: 21px;
                    margin-bottom: 0;
                }
                // end .btn
            }
            // end .account__account_navigation

                .account_navigation__menu
                {
                    background-color: $white;
                    list-style: none;

                    padding-bottom: 28px;
                }
                // end .account_navigation__menu

                    .account_navigation__menu_item
                    {
                    }
                    // end .account_navigation__menu_item

                    .account_navigation__menu_item[disabled] .account_navigation__menu_item_link {
                        color: #D2D2D2;
                        cursor: default;
                        pointer-events: none;
                    }

                        .account_navigation__menu_item_link
                        {
                            display: block;
                            padding: 15px 14px;

                            background-color: $white;

                            font-size: 15px;
                            line-height: 20px;

                            @include transition(background);

                            &:hover
                            {
                                text-decoration: underline;
                            }
                            // end .account_navigation__menu_item_link:hover

                            .account_navigation__menu_item--is_current &
                            {
                                position: relative;
                                z-index: 1;

                                padding-right: 35px;

                                background-color: $nav_grey;

                                font-family: $medium_font;

                                &::after
                                {
                                    content: '';

                                    position: absolute;
                                    z-index: -1;
                                    top: 50%;
                                    right: 14px;

                                    display: block;
                                    width: 7px;
                                    height: 11px;
                                    margin-top: -6px;

                                    background-image: url('../../images/nav-arrow.svg');
                                }
                                // end .account_navigation__menu_item--is_current .account_navigation__menu_item_link::after

                                &:hover
                                {
                                    text-decoration: none;
                                }
                                // end .account_navigation__menu_item--is_current .account_navigation__menu_item_link:hover
                            }
                            // end .account_navigation__menu_item--is_current .account_navigation__menu_item_link
                        }
                        // end .account_navigation__menu_item_link

            .account__competition_timeline
            {
                margin-top: 21px;

                font-size: 0;

                img
                {
                    display: block;
                }
                // end img
            }
            // end .account__competition_timeline

        .account__account_main
        {
            display: inline-block;
            vertical-align: top;
            width: 75%;
            padding-right: 7px;
            padding-left: 7px;
        }
        // end .account__account_main

            .account_main__title
            {
                max-width: none;
                margin-top: 0 !important;
                margin-bottom: 14px !important;
                padding: 7px 0 10px 0;

                font-family: $light_font;
                font-size: 27px;
                line-height: 33px;
                text-align: center;
            }
            // end .account_main__title

            .account_main__account_content
            {
                padding: 21px;

                background-color: $white;

                text-align: left;
            }
            // end .account_main__account_content

                .account_main__account_content > form > fieldset {
                    // Silverstripe requires a containing fieldset,
                    // remove styles from it.
                    padding: 0;
                    border: none;
                }

                .account_main__account_content > form {
                    // Various additional styles to ratify some quirks
                    .dropzone-holder {
                        > p {
                            display: none;
                        }
                        ul.file-attachment-field-previews.grid {
                            text-align: left;
                        }
                        ul.file-attachment-field-previews.grid .dropzone-file {
                            margin-left: 0;
                        }

                    }
                    &[disabled] {
                        .btn-upload {
                            display: none !important;
                        }

                        .dropzone-holder {
                            .dropzone-file {
                                overflow: initial;

                                &::before {
                                    position: absolute;
                                    display: block;
                                    content: '';
                                    top: -20px;
                                    left: 0;
                                    height: 15px;
                                    width: 15px;
                                    background-image: url('../../images/lock.svg');
                                    background-repeat: no-repeat;
                                }
                            }

                            ul.file-attachment-field-previews.grid {
                                background-color: $nav_grey;
                                pointer-events: none;
                            }
                            ul.file-attachment-field-previews.grid .dropzone-file .dropzone-actions {
                                display: none;
                            }
                        }
                    }

                }


                .account_content__title
                {
                    max-width: none;
                    margin: 0 !important;
                    padding-right: 14px;
                    padding-left: 14px;

                    font-family: $light_font;
					font-weight: bold;
                    font-size: 27px;
                    line-height: 33px;
                    text-align: left;

                    &.account_content__title--locked
                    {
                        position: relative;
                        z-index: 1;

                        padding-left: 42px;

                        &::before
                        {
                            content: '';

                            position: absolute;
                            z-index: -1;
                            top: 7px;
                            left: 14px;

                            display: block;
                            width: 20px;
                            height: 20px;

                            background-image: url('../../images/lock.svg');
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                        }
                        // end .account_content__title.account_content__title--locked::before
                    }
                    // end .account_content__title.account_content__title--locked
                }
                // end .account_content__title

                .account_content__introduction
                {
                    margin-top: 14px;
                    padding-right: 14px;
                    padding-left: 14px;

                    .account_main__inner_title + &
                    {
                        margin-top: 14px;
                    }
                    // end .account_main__inner_title + .account_main__inner_introduction

                    p
                    {
                        font-family: $light_font;
                        font-size: 18px;
                        line-height: 24px;

                        strong
                        {
                            font-family: $medium_font;
                        }
                        // end strong
                    }
                    // end p
                }
                // end .account_content__introduction

                .account_content__submission_result
                {
                    margin-top: 21px;
                    padding: 21px;

                    text-align: center;

					&:not(:last-child) {
						margin-bottom: 30px;

					}
                }
                // end .account_content__submission_result

                    .submission_result__introduction
                    {
                        font-size: 16px;
                        line-height: 21px;
                    }
                    // end .submission_result__introduction

                    .submission_result__grade
                    {
                        display: inline-block;
                        width: 100px;
                        height: 100px;
                        margin-top: 21px;

                        color: $white;
                        @include border_radius(50%);

                        font-family: $medium_font;
                        font-size: 42px;
                        line-height: 100px;

                        &[data-grade="A"]
                        {
                            background-color: $grade_a;
                        }
                        // end .submission_result__grade[data-grade="A"]

                        &[data-grade="B"]
                        {
                            background-color: $grade_b;
                        }
                        // end .submission_result__grade[data-grade="B"]

                        &[data-grade="C"]
                        {
                            background-color: $grade_c;
                        }
                        // end .submission_result__grade[data-grade="C"]

                        &[data-grade="D"]
                        {
                            background-color: $grade_d;
                        }
                        // end .submission_result__grade[data-grade="D"]

                        &[data-grade="W"]
                        {
                            background-color: $grade_w;
                        }
                        // end .submission_result__grade[data-grade="D"]
                    }
                    // end .submission_result__grade

                    .submission_result__grade_description
                    {
                        margin-top: 21px;

                        font-family: $medium_font;
                        font-size: 16px;
                        line-height: 21px;

                        [data-grade="A"] + &,
                        [data-grade="B"] + &
                        {
                            color: $grade_a;
                        }
                        // end [data-grade="A"] + .submission_result__grade_description
                        // end [data-grade="B"] + .submission_result__grade_description

                        [data-grade="C"] + &,
                        [data-grade="D"] + &
                        {
                            color: $grade_d;
                        }
                        // end [data-grade="C"] + .submission_result__grade_description
                        // end [data-grade="D"] + .submission_result__grade_description
                    }
                    // end .submission_result__grade_description

                    .submission_result__resubmit
                    {
                        margin-top: 21px;
                        margin-bottom: 0;
                    }
                    // end .submission_result__resubmit

                    .submission_result__footnote
                    {
                        margin-top: 21px;

                        font-size: 15px;
                        line-height: 21px;
                    }
                    // end .submission_result__footnote

                .account_content__submission_form
                {
                    margin-top: 27px;
					padding: 0 14px;
                }
                // end .account_content__submission_form

					.submission_form__section_title
					{
						font-size: 24px;
						text-align: left;
						margin: 0 0 20px !important;
						line-height: 1.1;
						max-width: none;

						&:not(:first-child) {
							margin-top: 60px !important;
						}
					}

                    .submission_form__fields
                    {
                        counter-reset: field_number;

                        .account_content__submission_form[disabled] &
                        {
                            position: relative;
                            z-index: 1;

                            padding-top: 21px;

                            &::before
                            {
                                content: '';

                                position: absolute;
                                z-index: -1;
                                top: 0;
                                right: -21px;
                                bottom: -21px;
                                left: -21px;

                                display: block;

                                background-color: $nav_grey;
                            }
                            // end .account_content__submission_form[disabled] .submission_form__fields::before
                        }
                        // end .account_content__submission_form[disabled] .submission_form__fields
                    }
                    // end .submission_form__fields

                        .submission_form__field
                        {
                            counter-increment: field_number;

                            & + .submission_form__field
                            {
                                margin-top: 21px;
                            }
							// end .submission_form__field + .submission_form__field

							&--alternate {
								counter-increment: unset !important;

								& + .submission_form__field
								{
									margin-top: 60px !important;
								}
							}
                        }
                        // end .submission_form__field

                            .submission_form__field_title
                            {
                                position: relative;
                                z-index: 1;

                                padding: 10px 14px 10px 64px;

                                background-color: $nav_grey;

                                font-size: 21px;
                                line-height: 27px;

                                &::before
                                {
                                    content: '';

                                    position: absolute;
                                    z-index: -2;
                                    top: 0;
                                    left: 0;

                                    display: block;
                                    width: 50px;
                                    height: 100%;

                                    background-color: $brand_green;
                                }
                                // end .submission_form__field_title::before

                                &::after
                                {
                                    content: counter(field_number);

                                    position: absolute;
                                    z-index: -1;
                                    top: 50%;
                                    left: 25px;

                                    color: $white;

                                    font-family: $medium_font;
                                    font-size: 21px;
                                    line-height: 27px;

                                    @include translate(-50%, -50%);
                                }
                                // end .submission_form__field_title::after

								.submission_form__field--alternate & {
									&::before {
										background-position: center;
										background-size: auto 50%;
										background-image: url("../../images/Document.svg");
										background-repeat: no-repeat;
									}
								}
								// end .submission_form__field--alternate .submission_form__field_title

                                .account_content__submission_form[disabled] &
                                {
                                    border: 1px solid $mid_grey;

                                    &::before
                                    {
                                        background-color: $mid_grey;
                                    }
                                    // end .account_content__submission_form[disabled] .submission_form__field_title::before
                                }
                                // end .account_content__submission_form[disabled] .submission_form__field_title
                            }
                            // end .submission_form__field_title

                            .submission_form__field_content
                            {
                                border-right: 1px solid $nav_grey;
                                border-bottom: 1px solid $nav_grey;
                                border-left: 1px solid $nav_grey;
                                padding: 0 !important;

                                .account_content__submission_form[disabled] &
                                {
                                    border-top: none !important;
                                    border-color: $mid_grey !important;
                                }
                                // end .account_content__submission_form[disabled] .submission_form__field_content
                            }
                            // end .submission_form__field_content

                                .submission_form__field_description
                                {
                                    padding: 7px;

                                    .wysiwyg
                                    {
                                        display: inline-block;
                                        vertical-align: top;
                                        width: (100% / 3) * 2;
                                        padding: 7px 35px 7px 7px;

                                        &:only-child
                                        {
                                            width: 100%;
                                        }
                                        // end .wysiwyg:only-child
                                    }
                                    // end .wysiwyg
                                }
                                // end .submission_form__field_description

                                    .submission_form__field_example
                                    {
                                        position: relative;
                                        z-index: 1;

                                        display: inline-block;
                                        vertical-align: top;
                                        width: (100% / 3);
                                        padding: 14px 56px 14px 14px;

                                        background-color: $brand_orange_light;
                                        color: $brand_orange;
                                        border: 7px solid $white;

                                        font-family: $medium_font;
                                        font-size: 16px;
                                        line-height: 24px;

                                        &::after
                                        {
                                            content: '';

                                            position: absolute;
                                            z-index: -1;
                                            top: 50%;
                                            right: 14px;

                                            display: block;
                                            width: 26px;
                                            height: 26px;
                                            margin-top: -13px;

                                            background-repeat: no-repeat;
                                            background-position: center center;
                                        }
                                        // end .submission_form__field_example::after

                                        &.submission_form__field_example--link::after
                                        {
                                            background-image: url('../../images/open-in-new-window.svg');
                                        }
                                        // end .submission_form__field_example--link::after

                                        &.submission_form__field_example--document::after
                                        {
                                            background-image: url('../../images/account-document.svg');
                                        }
                                        // end .submission_form__field_example--document::after

                                        &:hover
                                        {
                                            text-decoration: underline;
                                        }
                                        // end .submission_form__field_example:hover

                                        .account_content__submission_form[disabled] &
                                        {
                                            background-color: $super_light_grey;
                                            color: $mid_grey;
                                            border-color: $nav_grey;

                                            &.submission_form__field_example--link::after
                                            {
                                                background-image: url('../../images/open-in-new-window--mid-grey.svg');
                                            }
                                            // end .account_content__submission_form[disabled] .submission_form__field_example--link::after

                                            &.submission_form__field_example--document::after
                                            {
                                                background-image: url('../../images/account-document--mid-grey.svg');
                                            }
                                            // end .account_content__submission_form[disabled] .submission_form__field_example--document::after
                                        }
                                        // end .account_content__submission_form[disabled] .submission_form__field_example
                                    }
                                    // end .submission_form__field_example

                                .submission_form__field_input_container
                                {
                                    .fileattachment
                                    {
                                        border-bottom: 1px dashed $nav_grey;
									}

									&.optionset {
										.fieldholder-small-label {
											white-space: normal;
										}

										ul.optionset {
											display: flex;
											padding: 15px 0;

											li {
												display: flex;
												align-items: center;

												label {
													padding-left: 10px;
												}
											}
										}
									}

                                    .btn-upload,
                                    .message {
                                        margin-left: 14px;
                                    }

                                    .submission_form__field_description + &
                                    {
                                        border-top: 1px dashed $nav_grey;

                                        .account_content__submission_form[disabled] &
                                        {
                                            border-color: $mid_grey;
                                        }
                                        // end .account_content__submission_form[disabled] .submission_form__field_description + .submission_form__field_input_container
                                    }
                                    // end .submission_form__field_description + .submission_form__field_input_container
                                }
                                // end .submission_form__field_input_container

                                    .submission_form__field_label
                                    {
                                        display: inline-block;
                                        vertical-align: top;
                                        width: (100% / 9) * 2;
                                        padding: 17px 7px;

                                        font-size: 16px;
                                        line-height: 20px;

                                        &:hover
                                        {
                                            cursor: pointer;
                                        }
                                        // end .submission_form__field_label:hover
                                    }
                                    // end .submission_form__field_label

                                    .submission_form__field_input
                                    {
                                        display: inline-block;
                                        vertical-align: top;
                                        width: (100% / 9) * 4;
										padding: 7px;

										&--text {
											font-size: 16px;
											padding-top: 17px;
										}

                                        input[type="file"]
                                        {
                                            display: none;
                                        }
                                        // end input[type="file"]

                                        label
                                        {
                                            position: relative;
                                            z-index: 1;

                                            display: block;
                                            padding-top: 10px;
                                            padding-bottom: 10px;
                                            padding-left: (100% / 3) * 2;

                                            background-color: $brand_green;
                                            color: $white;
                                            border: 1px solid $brand_green;

                                            font-family: $medium_font;
                                            font-size: 15px;
                                            line-height: 20px;
                                            text-align: center;
                                            text-transform: uppercase;

                                            @include transition(background, 250ms);

                                            &::before
                                            {
                                                content: '';

                                                position: absolute;
                                                z-index: -1;
                                                top: 0;
                                                right: (100% / 3);
                                                bottom: 0;
                                                left: 0;

                                                display: block;

                                                background-color: $white;
                                            }
                                            // end label::before

                                            &:hover
                                            {
                                                background-color: lighten($brand_green, 10%);
                                                cursor: pointer;
                                            }
                                            // end label:hover
                                        }
                                        // end label
                                    }
                                    // end .submission_form__field_input

                    .submission_notification
                    {
                        .account_content__submission_form &
                        {
                            margin-top: 42px;
                        }
                        // end .account_content__submission_form .submission_notification
                    }
                    // end .submission_notification

                    .submission_form__controls
                    {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 42px;
                        padding-bottom: 21px;

                        .btn
                        {
                            margin: 0;

                            &:only-child
                            {
                                margin: 0 auto;
                            }
                            // end .btn:only-child

                            .no-flexbox &
                            {
                                margin-right: 50px;
                            }
                            // end .no-flexbox .submission_form__controls .btn

                            &:hover
                            {
                                cursor: pointer;
                            }
                            // end .btn:hover
                        }
                        // end .btn

                        &:first-child
                        {
                            margin-top: 0;
                        }
                        // and .submission_form__control:first-child
                    }
                    // end .submission_form__control
                #Form_ProfileForm {
                    margin-left: 14px;
                    margin-right: 14px;
                }
                .account_content__profile_settings
                {
                    margin-top: 35px;
                }
                // end .account_content__profile_settings

                    .profile_settings__field
                    {
                    }
                    // end .profile_settings__field

                        .profile_settings__field_label
                        {
                            display: inline-block;
                            vertical-align: middle;
                            width: (100% / 9) * 2;
                            padding: 7px 7px 7px 14px;

                            font-family: $medium_font;
                            font-size: 16px;
                            line-height: 20px;

                            &.profile_settings__field_label--required
                            {
                                &::after
                                {
                                    content: '*';

                                    color: $brand_orange;
                                }
                                // end .profile_settings__field_label.profile_settings__field_label--required::after
                            }
                            // end .profile_settings__field_label.profile_settings__field_label--required
                        }
                        // end .profile_settings__field_label

                        .profile_settings__field_input
                        {
                            display: inline-block;
                            vertical-align: middle;
                            width: (100% / 9) * 6;
                            padding: 7px;

                            input
                            {
                                width: 100%;
                                height: 40px;
                                padding: 0 14px;

                                background-color: $white;
                                color: $black;
                                border: 1px solid $nav_grey;
                                -webkit-appearance: none;
                                @include border_radius(0);

                                font-family: $light_font;
                                font-size: 15px;
                            }
                            // end input
                        }
                        // end .profile_settings__field_input

                    .profile_settings__controls
                    {
                        text-align: center;
                    }
                    // end .profile_settings__controls

div.field[id^="PartnershipEvidenceDocument"]
{
	display: none;

	& + a {
		display: none;
	}

	.field--visible & {
		display: block;

		& + a {
			display: inline-block;
		}
	}
}

#Form_OnlineApplicationSolverForm,
#Form_OnlineApplicationSolverFundingForm {
	.Actions {
		display: flex;
		flex-direction: row-reverse;
	}
}

ul#Form_OnlineApplicationSolverForm_HasCommercialPartnershipEvidence,
ul#Form_OnlineApplicationSolverFundingForm_HasCommercialPartnershipEvidence,
textarea#Form_OnlineApplicationSolverForm_CommercialPartnershipPlan,
textarea#Form_OnlineApplicationSolverFundingForm_CommercialPartnershipPlan {
	display: none !important;

	.field--visible & {
		display: flex !important;
	}
}

label[for=Form_OnlineApplicationSolverForm_HasCommercialPartnershipEvidence],
label[for=Form_OnlineApplicationSolverFundingForm_HasCommercialPartnershipEvidence],
label[for=Form_OnlineApplicationSolverForm_CommercialPartnershipPlan],
label[for=Form_OnlineApplicationSolverFundingForm_CommercialPartnershipPlan] {
	display: none !important;

	.field--visible & {
		display: table-cell !important;
	}
}


@media screen and (max-width: $site_width)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_main
            {
            }
            // end account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__submission_form
                    {
                    }
                    // end .account_content__submission_form

                        .submission_form__fields
                        {
                        }
                        // end .submission_form__fields

                            .submission_form__field
                            {
                            }
                            // end .submission_form__field

                                .submission_form__field_content
                                {
                                }
                                // end .submission_form__field_content

                                    .submission_form__field_description
                                    {
                                    }
                                    // end .submission_form__field_description

                                    .submission_form__field_input_container
                                    {
                                    }
                                    // end .submission_form__field_input_container

                                        .submission_form__field_label
                                        {
                                            width: (100% / 3);
                                        }
                                        // end .submission_form__field_label

                                        .submission_form__field_input
                                        {
                                            width: (100% / 3) * 2;
                                        }
                                        // end .submission_form__field_input

                    .account_content__profile_settings
                    {
                    }
                    // end .account_content__profile_settings

                        .profile_settings__field
                        {
                        }
                        // end .profile_settings__field

                            .profile_settings__field_label
                            {
                                width: (100% / 9) * 3;
                            }
                            // end .profile_settings__field_label
}
// end @media screen and (max-width: $site_width)










@media screen and (max-width: 1024px)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_header
            {
            }
            // end .account__account_header

                .account__account_title
                {
                    font-size: 27px;
                    line-height: 33px;
                }
                // end .account__account_title

            .account__account_main
            {
            }
            // end account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__title
                    {
                        font-size: 24px;
                        line-height: 30px;

                        &.account_content__title--locked
                        {
                            &::before
                            {
                                top: 4px;
                            }
                            // end .account_content__title.account_content__title--locked::before
                        }
                        // end .account_content__title.account_content__title--locked
                    }
                    // end .account_content__title

                    .account_content__submission_form
                    {
                    }
                    // end .account_content__submission_form

                        .submission_form__fields
                        {
                        }
                        // end .submission_form__fields

                            .submission_form__field
                            {
                            }
                            // end .submission_form__field

                                .submission_form__field_content
                                {
                                }
                                // end .submission_form__field_content

                                    .submission_form__field_description
                                    {
                                        .wysiwyg
                                        {
                                            width: (100% / 9) * 5;
                                        }
                                        // end .wysiwyg
                                    }
                                    // end .submission_form__field_description

                                        .submission_form__field_example
                                        {
                                            width: (100% / 9) * 4;
                                        }
                                        // end .submission_form__field_example
}
// end @media screen and (max-width: 1024px)










@media screen and (max-width: 896px)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_header
            {
            }
            // end .account__account_header

                .account__account_title
                {
                    font-size: 24px;
                    line-height: 30px;
                }
                // end .account__account_title

            .account__account_sidebar
            {
                width: (100% / 3);
            }
            // end .account__account_sidebar

            .account__account_main
            {
                width: (100% / 3) * 2;
            }
            // end account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__title
                    {
                        font-size: 21px;
                        line-height: 27px;
                    }
                    // end .account_content__title

                    .account_content__submission_form
                    {
                    }
                    // end .account_content__submission_form

                        .submission_form__fields
                        {
                        }
                        // end .submission_form__fields

                            .submission_form__field
                            {
                            }
                            // end .submission_form__field

                                .submission_form__field_title
                                {
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                                // end .submittion_form__field_title

                                .submission_form__field_content
                                {
                                }
                                // end .submission_form__field_content

                                    .submission_form__field_description
                                    {
                                        .wysiwyg
                                        {
                                            width: 100%;
                                        }
                                        // end .wysiwyg
                                    }
                                    // end .submission_form__field_description

                                        .submission_form__field_example
                                        {
                                            width: 100%;
                                        }
                                        // end .submission_form__field_example

                                    .submission_form__field_input_container
                                    {
                                    }
                                    // end .submission_form__field_input_container

                                        .submission_form__field_label
                                        {
                                            width: (100% / 8) * 3;
                                        }
                                        // end .submission_form__field_label

                                        .submission_form__field_input
                                        {
                                            width: (100% / 8) * 5;

                                            label
                                            {
                                                padding-left: 60%;

                                                &::before
                                                {
                                                    right: 40%;
                                                }
                                                // end label::before
                                            }
                                            // end label
                                        }
                                        // end .submission_form__field_input

                    .account_content__profile_settings
                    {
                    }
                    // end .account_content__profile_settings

                        .profile_settings__field
                        {
                        }
                        // end .profile_settings__field

                            .profile_settings__field_label
                            {
                                width: (100% / 8) * 3;
                            }
                            // end .profile_settings__field_label

                            .profile_settings__field_input
                            {
                                width: (100% / 8) * 5;
                            }
                            // end .profile_settings__field_input
}
// end @media screen and (max-width: 896px)










@media screen and (max-width: 768px)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_header
            {
            }
            // end .account__account_header

                .account__account_title
                {
                    padding-right: 14px;
                    padding-left: 14px;

                    font-size: 21px;
                    line-height: 27px;
                }
                // end .account__account_title

            .account__account_main
            {
            }
            // end account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__title
                    {
                        &.account_content__title--locked
                        {
                            &::before
                            {
                                top: 3px;
                            }
                            // end .account_content__title.account_content__title--locked::before
                        }
                        // end .account_content__title.account_content__title--locked
                    }
                    // end .account_content__title

                    .account_content__submission_form
                    {
                    }
                    // end .account_content__submission_form

                        .submission_form__fields
                        {
                        }
                        // end .submission_form__fields

                            .submission_form__field
                            {
                            }
                            // end .submission_form__field

                                .submission_form__field_content
                                {
                                }
                                // end .submission_form__field_content

                                    .submission_form__field_input_container
                                    {
                                    }
                                    // end .submission_form__field_input_container

                                        .submission_form__field_label
                                        {
                                            width: 100%;
                                            padding-top: 7px;
                                            padding-bottom: 0;

                                            font-family: $medium_font;
                                        }
                                        // end .submission_form__field_label

                                        .submission_form__field_input
                                        {
                                            width: 100%;

                                            label
                                            {
                                                padding-left: 75%;

                                                &::before
                                                {
                                                    right: 25%;
                                                }
                                                // end label::before
                                            }
                                            // end label
                                        }
                                        // end .submission_form__field_input

                    .account_content__profile_settings
                    {
                    }
                    // end .account_content__profile_settings

                        .profile_settings__field
                        {
                            margin-top: 28px;
                        }
                        // end .profile_settings__field

                            .profile_settings__field_label
                            {
                                width: 100%;
                                padding: 0 14px;
                            }
                            // end .profile_settings__field_label

                            .profile_settings__field_input
                            {
                                width: 100%;
                                margin-top: 7px;
                                padding: 0 14px;
                            }
                            // end .profile_settings__field_input
}
// end @media screen and (max-width: 768px)










@media screen and (max-width: 640px)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_sidebar
            {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
            }
            // end .account__account_sidebar

                .account__account_navigation
                {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    padding-right: 7px;
                    padding-left: 7px;
                }
                // end .account__account_navigation

                .account__competition_timeline
                {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    margin-top: 0;
                    padding-right: 7px;
                    padding-left: 7px;
                }
                // end .account__competition_timeline

            .account__account_main
            {
                width: 100%;
                margin-top: 21px;
            }
            // end .account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__title
                    {
                        padding-right: 0;
                        padding-left: 0;

                        &.account_content__title--locked
                        {
                            padding-left: 28px;

                            &::before
                            {
                                left: 0;
                            }
                            // end .account_content__title.account_content__title--locked::before
                        }
                        // end .account_content__title.account_content__title--locked
                    }
                    // end .account_content__title

                    .account_content__introduction
                    {
                        padding-right: 0;
                        padding-left: 0;
                    }
                    // end .account_content__introduction

                    .account_content__profile_settings
                    {
                    }
                    // end .account_content__profile_settings

                        .profile_settings__field
                        {
                            margin-top: 28px;
                        }
                        // end .profile_settings__field

                            .profile_settings__field_label
                            {
                                padding-right: 0;
                                padding-left: 0;
                            }
                            // end .profile_settings__field_label

                            .profile_settings__field_input
                            {
                                padding-right: 0;
                                padding-left: 0;
                            }
                            // end .profile_settings__field_input
}
// end @media screen and (max-width: 640px)










@media screen and (max-width: 512px)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_sidebar
            {
            }
            // end .account__account_sidebar

                .account__account_navigation
                {
                    display: inline-block;
                    width: 100%;
                }
                // end .account__account_navigation

                .account__competition_timeline
                {
                    display: none;
                }
                // end .account__competition_timeline

            .account__account_main
            {
            }
            // end .account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__submission_form
                    {
                    }
                    // end .account_content__submission_form

                        .submission_form__controls
                        {
                            display: block;

                            .btn
                            {
                                display: block;
                                width: auto;
                                margin: 0 auto;

                                & + .btn
                                {
                                    margin-top: 21px;
                                }
                                // end .btn + .btn
                            }
                            // end .btn
                        }
                        // end .submission_form__controls
}
// end @media screen and (max-width: 512px)










@media screen and (max-width: 384px)
{
    .panel--account
    {
    }
    // end .panel--account

        .site_width
        {
            .panel--account &
            {
            }
            // end .panel--account .site_width
        }
        // end .site_width

            .account__account_main
            {
            }
            // end .account__account_main

                .account_main__account_content
                {
                }
                // end .account_main__account_content

                    .account_content__submission_form
                    {
                    }
                    // end .account_content__submission_form

                        .submission_form__fields
                        {
                        }
                        // end .submission_form__fields

                            .submission_form__field
                            {
                            }
                            // end .submission_form__field

                                .submission_form__field_content
                                {
                                }
                                // end .submission_form__field_content

                                    .submission_form__field_input_container
                                    {
                                    }
                                    // end .submission_form__field_input_container

                                        .submission_form__field_input
                                        {
                                            label
                                            {
                                                padding-top: 50px;
                                                padding-left: 0;

                                                &::before
                                                {
                                                    width: 100%;
                                                    height: 40px;
                                                }
                                                // end label::before
                                            }
                                            // end label
                                        }
                                        // end .submission_form__field_input
}
// end @media screen and (max-width: 384px)
