/*
 * Solver Profile Listing
 */
.solver-profiles {
  border: 1px solid $light_grey;
  padding: 42px;
  background-color: white;
}

.solver-profiles--heading h1 {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: "DINMedium";
  font-size: 28px;
  line-height: 34px;
}

.solver-profiles--content {
  margin-bottom: 20px;
}

.solver-profiles--list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.solver-profiles--list-item {
  width: 100%;
  margin-bottom: 21px;
  border: 1px solid $ultra_ultra_light_grey;
  padding: 21px;
}

.solver-profiles--item-logo-wrapper {
  position: relative;
  border: 1px solid #D1D1D1;
  padding-top: 100%;
  width: 100%;
}

.solver-profiles--item-logo-wrapper > a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin: 21px;
  height: calc(100% - 42px);
  width: calc(100% - 42px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.solver-profiles--item-content {}

.solver-profiles--item-heading {
  margin: 0;
  margin-top: 16px;
}

.solver-profiles--item-tagline {
  color: $brand_brown;
  font-family: "DINMedium";
  font-size: 16px;
}

.solver-profiles--item-excerpt {
  margin-top: 21px;
}

.solver-profiles--item-excerpt p {
  font-size: 16px;
}

.solver-profiles--item-heading a,
.solver-profiles--item-link {
  font-family: "DINMedium";
}

.solver-profiles--item-heading a {
  font-size: 21px;
  color: $brand_green;
}

.solver-profiles--item-link {
  display: block;
  margin-top: 10px;
  color: $brand_orange;
  font-size: 16px;
}

@media screen and (min-width: 670px) {
	.solver-profiles--list-item {
		width: calc(50% - 20px);
		margin: 0 20px 20px 0;

		&:nth-child(2n) {
			margin: 0 0 20px 0;
		}
	}
}

@media screen and (min-width: 780px) {
  .solver-profiles--list-item {
	width: calc((100% / 3) - 14px);
	margin: 0 20px 20px 0;

	&:nth-child(2n) {
		margin: 0 20px 20px 0;
	}

	&:nth-child(3n) {
		margin: 0 0 20px 0;
	}
  }
}

@media screen and (min-width: 1024px) {
  .solver-profiles--list-item {
	width: calc(25% - 15px);
	margin: 0 20px 20px 0;

	&:nth-child(2n),
	&:nth-child(3n) {
		margin: 0 20px 20px 0;
	}

	&:nth-child(4n) {
		margin: 0 0 20px 0;
	}
  }
}

/*
 * Solver Profile View
 */
.solver-profile {
  margin: 0 21px;
  margin-top: 10px;
}

.solver-profile--sidebar {
  margin-bottom: 21px;
}

.solver-profile--sidebar-logo {
  padding: 42px;
  border: 1px solid $light_grey;
  text-align: center;
  background-color: white;
}

.solver-profile--sidebar-details {
  padding: 42px;
  color: white;
  background-color: $brand_green;
}

.solver-profile--sidebar-detail {
  margin-bottom: 42px;
}

.solver-profile--sidebar-detail > p {
  text-overflow: ellipsis;
  overflow: hidden;
}

.solver-profile--sidebar-detail > p a {
  color: white;
}

.solver-profile--sidebar-details-label {
  opacity: 0.9;
}

.solver-profile--sidebar-details-social {
  display: flex;
}

.solver-profile--sidebar-details-social-item {
  display: block;
  margin-right: 11px;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: white;
}

.solver-profile--sidebar-details-social-item > a {
  padding: 8px;
  color: $brand_green;
}

.solver-profile--sidebar-details-social-item > a,
.solver-profile--sidebar-details-social-item > a > svg {
  display: block;
  width: 100%;
  height: 100%;
}

.solver-profile--sidebar-details-social-item--facebook {}
.solver-profile--sidebar-details-social-item--twitter {}
.solver-profile--sidebar-details-social-item--linkedin {}

.solver-profile--content {
  border: 1px solid $light_grey;
  background-color: white;
}

.solver-profile--header {
  padding: 42px;
  text-align: left;
  background-color: #e7e7e7;
}

.solver-profile--header h1 {
  margin: 0;
  font-family: "DINMedium";
  font-size: 28px;
  line-height: 34px;
  max-width: none;
}

.solver-profile--team-banner {
  margin-bottom: 42px;
}

.solver-profile--body {
  padding: 42px;
}

.solver-profile--body-section {
  margin-bottom: 42px;
  border-bottom: 1px solid $light_grey;
  padding-bottom: 42px;
}

.solver-profile--body-section:last-of-type {
  margin-bottom: 0;
}

.solver-profile--body-section-headline {
  margin: 0;
  margin-bottom: 21px;
  font-size: 21px;
  color: $brand_orange;
  font-family: "DINMedium";
}

.solver-profile--body-section p {
  margin-bottom: 21px;
  font-size: 16px;
}

.solver-profile--body-section p:last-of-type {
  margin-bottom: 0;
}

.solver-profile--body-section p a {
  text-decoration: underline;
  text-decoration-skip: edges;
  transition: color 0.2s linear;
}

.solver-profile--body-section p a:hover {
  color: $brand_green;
}

.solver-profile--breadcrumbs {
  margin-top: -45px;
  margin-bottom: 0;
  margin-left: 21px;
}

.solver-profile--breadcrumbs > a > svg {
  height: 28px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (min-width: 1024px) {
  .solver-profile {
    display: flex;
    margin: 0;
    margin-top: 10px;
  }

  .solver-profile--sidebar {
    flex: 1;
    margin-right: 21px;
    max-width: 320px;
  }

  .solver-profile--content {
    flex: 3;
  }

  .solver-profile--header {
    padding-right: 80px;
    padding-left: 80px;
  }

  .solver-profile--body {
    padding: 70px 80px;
  }
  .solver-profile--team-banner {
    margin-bottom: 70px;
  }

  .solver-profile--body-section {
    margin-bottom: 70px;
    padding-bottom: 70px;
  }
  .solver-profile--breadcrumbs {
    margin-left: 0;
  }
}
