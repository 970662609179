.ui-widget.ui-tooltip.submissions-tooltip {
  position: relative;
  background: $super_light_grey;
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.ui-widget.ui-tooltip.submissions-tooltip::before {
  content: '';
  position: absolute;
  top: -25px;
  width: 0;
  height: 0;
  border-bottom: 25px solid $super_light_grey;
  border-right: 25px solid transparent;
}