/* Layout: _blog-section-holder.scss
---------------------------------------------------------------------------------- */
/*------------------------------------
	Two column
------------------------------------*/
@media (min-width: 768px) {
	.blog-section-holder__two-column {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(2, 1fr);
		margin: 20px 0;
	}
}

/*------------------------------------
	With sidebar
------------------------------------*/
.blog-section-holder__with-sidebar {
	display: grid;
	gap: 20px 13px;
}

@media (min-width: 768px) {
	.blog-section-holder__with-sidebar {
		grid-template-columns: 1fr 270px;
	}
}
