/* Layout: _blog-category-navigation.scss
---------------------------------------------------------------------------------- */
.blog-category-navigation {
	@include box_sizing();
	background-color: $blog-grey;
	padding: 13px 15px;
}

@media (min-width: 768px) {
	.blog-category-navigation {
		padding: 0 20px;
	}
}

/*------------------------------------
	Menu
------------------------------------*/
.blog-category-navigation__menu {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 29px;
	justify-content: center;
	margin: 0 auto;
	max-width: 1000px;
	padding: 13px 0;
	width: 100%;
}

@media (max-width: 767px) {
	.blog-category-navigation__menu {
		display: none;
	}
}

/*------------------------------------
	Item
------------------------------------*/
.blog-category-navigation__menu-item {
	position: relative;

	&:not(:first-child) {
		&:before {
			background-color: $dark_grey;
			content: '';
			inset: 0 auto 0 -15px;
			position: absolute;
			width: 1px;
		}
	}

	&--current {
		&:after {
			background-color: $super_light_grey;
			bottom: -13px;
			clip-path: polygon(0 100%, 50% 0, 100% 100%);
			content: '';
			height: 8px;
			left: calc(50% - 10px);
			position: absolute;
			width: 20px;
		}
	}
}

/*------------------------------------
	Link
------------------------------------*/
.blog-category-navigation__menu-link {
	color: $orange;
	font-family: $medium_font;

	&:active,
	&:hover {
		color: $brand_green;
	}
}

/*------------------------------------
	Mobile menu
------------------------------------*/
.blog-category-navigation__mobile-menu {
	align-items: center;
	display: flex;
	gap: 13px;

	label {
		flex-shrink: 0;
		font-size: 16px;
	}
}

@media (min-width: 768px) {
	.blog-category-navigation__mobile-menu {
		display: none;
	}
}

/*------------------------------------
	Mobile menu select
------------------------------------*/
.blog-category-navigation__mobile-menu-select {
	flex: 1 1 100%;
	position: relative;

	select {
		appearance: none;
		background-color: $white;
		border: none;
		border-radius: 0;
		color: $orange;
		display: block;
		font-size: 17px;
		margin: 0;
		padding: 10px 36px 10px 18px;
		width: 100%;
	}

	svg {
		inset: calc(50% - 6px) 14px auto auto;
		pointer-events: none;
		position: absolute;
		z-index: 2;
	}
}
