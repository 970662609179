.file
{
    position: relative;

    padding: 10px 35px 10px 0;

    background-repeat: no-repeat;
    background-position: left 11px;
    background-size: 12px 16px;
    overflow: hidden;

    font-size: 15px;
    line-height: 20px;
    text-overflow: ellipsis;

    .file__delete,
    .file__open
    {
        position: absolute;
        top: 6px;
        right: 0;

        display: block;
        width: 26px;
        height: 26px;
        padding: 0;

        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;

        &:hover
        {
            cursor: pointer;
        }
        // end .file__delete:hover
        // end .file__open:hover
    }
    // end .file__delete
    // end .file__open

    .file__delete
    {
        background-image: url('../../images/bin.svg');
    }
    // end .file__delete

    .file__open
    {
        background-image: url('../../images/open-in-new-window.svg');
    }
    // end .file__open

    &:first-of-type
    {
        margin-top: 1px;
    }
    // end .file:first-of-type

    &:last-of-type
    {
        padding-bottom: 21px;

        &:last-child
        {
            padding-bottom: 10px;
        }
        // end .file:last-of-type:last-child
    }
    // end .file:last-of-type

    &.file--locked
    {
        padding-left: 21px;

        background-image: url('../../images/lock.svg');
    }
    // end .file.file--locked

    &.file--unlocked
    {
        padding-left: 21px;

        background-image: url('../../images/unlock.svg');
    }
    // end .file.file--unlocked
}
// end .file