.panel--page
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    font-size: 0;

    .site_width
    {
        width: $site_width;
        margin: 0 auto;

        @media screen and (max-width: $site_width)
        {
            width: 100%;
        }
        // end @max-width $site_width
    }
    // end .site_width
}
// end .panel--page

    .page__page_main
    {
        display: inline-block;
        vertical-align: top;
        width: 75%;
        padding: 0 7px;

        @media screen and (max-width: 896px)
        {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
        }
        // end @max-width 896px
    }
    // end .page__page_main

        .page_main__content
        {
            padding: 70px 80px;

            background-color: $white;
            border: 1px solid $light_grey;

            h1
            {
                margin-top: 0;
                margin-bottom: 30px;

                font-family: $medium_font;
                font-size: 28px;
                line-height: 34px;
            }
            // end h1

            h2
            {
                display: block;
                max-width: none;
                margin-top: 30px;
                margin-bottom: 20px;
                margin-left: 0;

                color: $brand_green;

                font-family: $medium_font;
                font-size: 18px;
                line-height: 24px;
                text-align: left;
            }
            // end h2

            p
            {
                margin-bottom: 20px;

                font-size: 14px;
                line-height: 24px;

                a:not(.btn)
                {
                    color: $brand_green;

                    font-family: $medium_font;

                    &[target="_blank"]
                    {
                        &::after
                        {
                            content: '';

                            display: inline-block;
                            vertical-align: middle;
                            width: 12px;
                            height: 12px;
                            margin-top: -3px;
                            margin-left: 5px;

                            background-image: url('../../images/external_link.svg');
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                        }
                        // end a[target="_blank"]::after
                    }
                    // end a[target="_blank"]

                    &:hover
                    {
                        text-decoration: underline;
                    }
                    // end a:hover
                }
                // end a

                &:last-child
                {
                    margin-bottom: 0;
                }
                // end p:last-child
            }
            // end p

            img
            {
            }
            // end img

            ul
            {
                list-style-type: inherit;
                font-size: 14px;
                line-height: 24px;
                margin-left: 20px;
                margin-bottom: 30px;

                &.accordion
                {
                    list-style-type: none;
                    font-size: inherit;
                    line-height: inherit;
                    margin-left: inherit;
                }
            }
            // end ul

            strong {
                font-family: "DINMedium";
            }

            em {
                font-style: italic;
            }

            .btn
            {
                margin-top: 0;
                margin-bottom: 30px;
                padding-right: 40px;
                padding-bottom: 18px;
                padding-left: 40px;

                font-family: $light_font;
                font-size: 14px;

                &.btn-download
                {
                    padding-left: 70px;
                }
                // end .btn.btn-download

                & + h2
                {
                    margin-top: 10px;
                }
                // end .btn + h2
            }
            // end .btn

            :last-child
            {
                margin-bottom: 0;
            }
            // end :last-child

            @media screen and (max-width: 768px)
            {
                padding: 50px 40px;
            }
            // end @max-width: 768px

            @media screen and (max-width: 640px)
            {
                padding: 30px 20px;
            }
            // end @max-width: 640px
        }
        // end .page_main__content

    .page__page_sidebar
    {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding: 0 7px;

        @media screen and (max-width: 896px)
        {
            width: 100%;
            margin-top: 40px;
            padding-right: 0;
            padding-left: 0;
        }
        // end @max-width 896px
    }
    // end .page__page_sidebar

        .page_sidebar__sidebar_block
        {
            padding: 30px;

            background-color: $brand_green;

            text-align: center;
        }
        // end .page_sidebar__sidebar_block

    .panel--completed
    {
        strong
        {
            color: $brand_green;
        }
    }