.wysiwyg
{
    & > *
    {
        margin-top: 10px;
    }
    // end .wysiwyg > *

    & > :first-child
    {
        margin-top: 0;
    }
    // end .wysiwyg > :first-child

    p
    {
        font-size: 15px;
        line-height: 24px;

        a
        {
            &:hover
            {
                text-decoration: underline;
            }
            // end a:hover
        }
        // end a
    }
    // end p

    ul
    {
        padding-left: 21px;

        list-style: disc;

        li
        {
            font-size: 15px;
            line-height: 24px;
        }
        // end li
    }
    // end ul

	ol {
		padding-left: 21px;
		list-style: decimal;

		li {
            font-size: 15px;
            line-height: 24px;
        }
	}
}
// end .wysiwyg
