.flash-container {
  margin: 0 auto;
  width: 100%;
  max-width: $site_width;
}

.flash {
  padding: 15px;
  margin-top: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.flash.flash-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.flash > p > a {
  display: inline-block;
  vertical-align: middle;
}

// Flash modal
.flash-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 90%;
	max-width: 768px;
	background-color: $white;
	border: solid $orange;
	border-width: 40px 6px 6px;
	padding: 20px;
	z-index: 999;
	transform: translate(-50%, -50%);

	.flash-modal__container--closed & {
		visibility: hidden;
		opacity: 0;
	}
}

.flash-modal__close {
	position: absolute;
	top: -27px;
	right: 10px;
	color: $white;
	font-weight: bold;
	cursor: pointer;
}

.flash-modal__cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 998;
	background-color: rgba($black, .45);
	transition: all .2s ease-in-out;

	.flash-modal__container--closed & {
		visibility: hidden;
		opacity: 0;
	}
}
