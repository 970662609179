// main: ../style.scss

.submission-info {
  .rating-record {
    padding-left: 37px;
    height: 25px;
    font-size: 16px;

    display: flex;
    flex-direction: row;
    margin: 24px 0 0 0;

    background-image: url('../../images/RatingDone.svg');
    background-repeat: no-repeat;
    background-position: center left;
  }
}

.rating-record {
  &.hidden {
    display: none;
  }
}

.rating-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#494949, 0.71);
  flex-direction: column;
  display: none;
  z-index: 9999;

  .rating-panel {
    width: 100%;
    max-width: 895px;
    position: absolute;
    border-radius: 5px;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    .rating-header {
      height: 96px;
      background-color: #D7D7D7;
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      justify-content: center;
      min-height: 0;
      padding-top: 6px;
      box-sizing: border-box;

      h3 {
        font-size: 24px;
      }

      .rating-legend {
        font-size: 24px;
        margin-left: 24px;
      }

      .rating-close {
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: 18px;
        padding-right: 16px;

        &::after {
          content: 'X';
          position: absolute;
          right: 0;
          top: 2px;
          font-size: 18px;
          font-family: $medium_font;
        }
      }
    }

    .rating-form {
      fieldset {
        padding: 0;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        .field {
          border-bottom: 1px solid #E5E5E5;

          &.optionset {
            padding-top: 32px;
            padding-bottom: 26px;
            padding-left: 120px;
            padding-right: 106px;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            label {
              text-align: center;
              font-size: 18px;
              font-family: $default-font;

              strong {
                font-family: $medium_font;
              }
            }

            input[type=radio] {
              -webkit-appearance: radio;
              -moz-appearance: radio;
              -ms-appearance: radio;
            }

            ul.optionset {
              display: flex;
              flex-direction: row;
              margin-top: 16px;
              border: 1px solid #E5E5E5;
              border-width: 0 0 1px 1px;

              li {
                width: 90px;
                height: 83px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #FBFBFB;
                border: 1px solid #E5E5E5;
                border-width: 1px 1px 0 0;
                position: relative;

                label {
                  order: 1;
                  margin: 0;
                  font-size: 20px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  padding-top: 18px;
                }

                input {
                  order: 2;
                  margin-top: 30px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .Actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
      }
    }
  }
}
