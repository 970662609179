/* Layout: _blog-button.scss
---------------------------------------------------------------------------------- */
a,
button {
	&.blog-button {
		align-items: center;
		background-color: $brand_green;
		border: none;
		border-radius: 40px;
		color: $white;
		cursor: pointer;
		display: inline-flex;
		font-family: $medium-font;
		font-size: 16px;
		gap: .25em;
		justify-content: center;
		min-height: 36px;
		outline: none;
		padding: 6px 20px 2px 20px;
		text-align: center;
		text-transform: uppercase;
		transition: background-color .25s;

		&:active,
		&:hover {
			background-color: #7B9351;
		}
	}
}

/*------------------------------------
	Title
------------------------------------*/
.blog-button__title {
	font-family: inherit;
}

@media (max-width: 767px) {
	.blog-button__title {
		display: none;
	}
}
