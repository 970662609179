// main: style.scss

.form-heading {
    margin-top: 40px;
    // height: 85px;
    min-height: 75px;
    background-color: $light_grey;
    border-radius: 3px 3px 0 0;
    padding: 27px 100px 25px 100px;
    display: block;

    .orange-dot {
        display: inline-block;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: $brand_orange;
        margin-right: 10px;
        margin-bottom: 4px;
    }
    h3 {
        display: inline-block;
        margin: 0;
        padding: 0;
        font-size: 22px;
    }
}

.readonly-textarea {
	padding: 15px 10px;
	background: $super_light_grey;
	margin: 10px auto;
}

fieldset {
    background-color: $white;
    border: 1px solid $light_grey;
    padding: 50px 100px;
    @media (max-width: 900px) {
        padding: 10px;
    }
    margin-bottom: 40px 0;
    border-radius: 0 0px 3px 3px;
    legend {
        float: left;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
    }
    .field {
        clear: both;
        &.CompositeField.fieldgroup > div {
            border: 1px $light_grey solid;
            padding: 20px;
            .fieldholder-small {
                display: table;
                table-layout: fixed;
                width: 100%;
                padding-bottom: 20px;

                @media screen and (max-width: 768px)
                {
                    padding-bottom: 0;
                }
            }
        }
        &.numeric .middleColumn {
            width: 200px;
        }
        &.simplecaptcha {
            label.input-captcha {
                img {
                    position: relative;
                    margin-bottom: -12px;
                    right: inherit;
                    top: inherit;
                    border: 1px solid #e5e5e5;
                }
            }
        }
        label {
            display: table-cell;
            //margin-right: 25px;
            padding-right: 25px;
            font-size: 16px;
            &.fieldholder-small-label {
                //width: 200px;
                width: 25%;
                margin-top: 25px;
                text-align: right;
                vertical-align: top;
                padding-top: 10px;

                @media screen and (max-width: 768px) {
                    display: block;
                    width: 100%;
                }
            }
            i {
                font-style: italic;
            }
            a:not(.btn)
            {
                color: $brand_green;
                font-family: $medium_font;

                &[target="_blank"]
                {
                    &::after
                    {
                        content: '';

                        display: inline-block;
                        vertical-align: middle;
                        width: 12px;
                        height: 12px;
                        margin-top: -3px;
                        margin-left: 5px;

                        background-image: url('../../images/external_link.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                    }
                    // end a[target="_blank"]::after
                }
                // end a[target="_blank"]

                &:hover
                {
                    text-decoration: underline;
                }
                // end a:hover
            }
            // end a
        }
        &.CompositeField.fieldgroup {
            margin-top: 25px;
        }
        &.composite {
            //display: flex;
            //justify-content: center;
            @media (max-width: 900px) {
                flex-wrap: wrap;
            }
            .fieldgroup:first-child {
                display: inline-block;
                width: 50%;
                font-size: 16px;
                padding-right: 10px;
                vertical-align: top;

                @media (max-width: 900px) {
                    margin-right: 0;
                }
            }
            .fieldgroup:last-child {
                display: inline-block;
                width: 50%;
                font-size: 16px;
                padding-left: 10px;
                vertical-align: top;

                @media (max-width: 900px) {
                    margin-left: 0;
                }
            }
            .fieldgroup .fieldgroup {
                border: 1px solid $light_grey;
                // padding: 20px;
                min-width: 100px;
                margin: 0;
                width: 100%;

                label
                {
                    display: block;
                }

                .text {
                    display: block;
                    padding: 5px;
                    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                    box-sizing: border-box;         /* Opera/IE 8+ */
                }
                .readonly {
                    padding: 12px 0 9px 10px;
                    margin: 10px 0 30px 0;
                }
            }
        }
        &.readonly {
            //display: flex;
            //flex-direction: column;

            label {
                //order: 1;
            }

            .middleColumn {
                //order: 3;
                .readonly
                {
                    display: block;
                }
            }

            .description {
                //order: 2;
            }
        }
        &.fileattachment {
            padding: 14px;

            > label {
                padding-bottom: 15px;
                font-family: $light_font;
                font-size: 15px;
            }

			&.fileattachment--with-description {
				> label {
					font-size: 18px;
					color: $brand_orange;
					font-weight: bold;
					padding-bottom: 0;
				}
			}

            .dropzone-holder,
            .dropzone-holder.uploadable {
                border: none;
                margin: 0;
                padding: 0;
            }

            ul.file-attachment-field-previews {
                width: 100%;
                text-align: center;
                margin-top: 20px;

                &.grid li {
                    display: inline-block;
                    margin-bottom: 0;
                    float: inherit;
                }
            }

            a {
                margin-bottom: 0;
            }
        }
        .readonly {
            background-color: $ultra_light_grey;
            border: 1px solid $light_grey;
            //width: 70%;
            min-height: 45px;
            display: table-cell;
            margin: 10px 0;
            padding: 12px 8px;

            box-sizing: border-box;
            font-size: 18px;
            /* Causes perm scroll bars in IE */
			//overflow: scroll;

			&.readonly--no {
				background-color: $bad_red;
				color: $white;
			}

			&.readonly--yes {
				background-color: $brand_green;
			}

			&.readonly--defer {
				background-color: $brand_orange;
			}

            @media screen and (max-width: 768px)
            {
                display: block;
            }

            &.textarea {
                height: 180px;
                margin-bottom: 30px;
                padding: 8px 8px;
                line-height: 1.35em;
				font-size: 16px;
				resize: vertical;
            }
        }
        &.textarea {
            display: block;
            &.readonly {
                display: block;
            }
        }
        &.composite
        {
            font-size: 0;
        }
        &.optionset {
            display: block;
            margin: 20px 0;
            input {
                margin: 10px 0;
                &[type=radio] {
                    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
                    -moz-appearance: checkbox;    /* Firefox */
                    -ms-appearance: checkbox;     /* not currently supported */
                    border: none;
                }
            }
            li  label {
                font-family: $default-font;
            }
        }
        .middleColumn {

            input {
                &.text {
                    font-family: $default-font;
                    height: 45px;
                    background-color: $ultra_light_grey;
                    border: 1px solid $light_grey;
                    margin: 10px 0;
                    width: 100%;
                    // padding: 0 10px;
                    font-size: 18px;

                    &.SimpleCaptchaField {
                        width: 50%;
                    }
                }
            }
        }
    }
    label {
        font-family: $medium-font;
        font-size: 20px;
    }
    #ForgotPassword
    {
        text-decoration: underline;
    }
    .action {
        margin-left: 200px;
    }
}

form {
    .message.required,
    .message.validation {
        color: $bad_red;
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
    }

    input.holder-validation {
        border: 1px $bad_red solid !important;
    }
    textarea {
        width: 300px;
		height: 180px;
		resize: vertical;
    }
    .textarea
    {

        &.wordlimit--5000,
        &.wordlimit--1500
        {
            position: relative;

            &::after
            {
                display: block;
                position: absolute;
                right: 4px;
                bottom: 35px;
                left: 4px;

                background-color: #F4F4F4;

                padding-left: 10px;
                padding-top: 10px;
                padding-right: 10px;
                padding-bottom: 5px;

                font-size: 14px;
            }

            &.readonly
            {
                &:after
                {
                    display: none !important;
                }
            }
        }

        &.wordlimit--5000
        {

            &::after
            {
                content: '*Max 5000 characters. If you require additional space for data entry, supporting documents can be uploaded at the end of this application';
            }
        }

        &.wordlimit--1500
        {

            &::after
            {
                content: '*Max 1500 characters. If you require additional space for data entry, supporting documents can be uploaded at the end of this application';
            }
        }
    }

    input.password {
        flex-grow: 1;
        padding: 5px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
    }
    input.email {
        padding: 5px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
    }
    .fieldgroup-field {
        width: 100%;
    }

    .field.nocaptcha {
        label {
            opacity: 0;
        }
        .description {
            display: none;
        }
    }

    .description {
        display: block;
        margin-top: 5px;
    }

    select {
        font-family: $default-font;
        height: 45px;
        background-color: $ultra_light_grey;
        border: 1px solid $light_grey;
        margin: 10px 0;
        width: 100%;
        padding: 5px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;
        font-size: 18px;
    }

    .field.checkbox {
        display: block;
        margin: 10px 0;
    }

    textarea {
        font-family: $default-font;
        background-color: $ultra_light_grey;
        border: 1px solid $light_grey;
        margin: 10px 0;
        width: 100%;
        padding: 10px 10px 45px 10px;
        font-size: 16px;
        height: 150px;
        margin-bottom: 30px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
		-moz-box-sizing: border-box;
		resize: vertical;
    }
    select.multiselect {
        margin-bottom: 30px;
        height: 150px;
    }
    .info-field {
        margin: 10px 0 20px 225px;
    }

    .Actions {
        text-align: right;
        input {
            margin: 0;
            margin-top: 30px;
        }

        .action--alternate {
            background-color: $brand_orange;
            margin-right: 15px;
            background-image: none;
            padding-left: 22px;
            &:hover {
                background-color: lighten($brand_orange, 10);
            }
            &:active {
                background-color: darken($brand_green, 10);
            }
        }
    }
}

// ONE OFFS
#Form_OnlineApplicationFormTwo_Summary_Holder,
#Form_OnlineApplicationFormTwo_VaccineStratagey_Holder
{

    textarea
    {
        height: 500px;
    }
}

#CaptchaMemberLoginForm_LoginForm
{

    fieldset
    {

        .password
        {
            padding-top: 15px;
        }

        .checkbox
        {
            display: inline-block;
            padding-top: 25px;

            label
            {
                display: inline-block;
            }
        }
    }

    .Actions
    {

        .readonly
        {
            padding-right: 20px;
            font-family: $medium-font;
        }
    }
}

#Form_AssessmentForm > fieldset
{
    border: none;
    padding: 0;
}

.fieldgroup .fieldgroup-field .fieldholder-small {
    display: block;
}

// Bo slecta, ill sort this out if I have time
#Form_OnlineApplicationFormOne > fieldset > div.CompositeField.field.composite.nolabel > div > div > div > div {
    display: block;
    input {
        margin-bottom: 30px;
    }
    label
    {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin-top: 0;
    }

    .readonly
    {
        display: block;
    }
}

input[type=text] {
    padding: 5px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

#MemberLoginForm_LoginForm {
    .field.checkbox {
        display: block;
        margin-left: 225px;
    }
}

#Form_UploadForm {
    fieldset {
        margin: 30px 0;
        background: none;
        border: none;
        padding: 0;
        text-align: center;
        .composite {
            width: 100%;
            display: block;
            font-size: 0;

            .field {
                padding: 0;
                display: inline-block;
                vertical-align: top;
                width: 49%;
                //margin: 10px;
                background-color: $white;
                padding: 44px 22px 12px 22px;
                border: 1px solid $light_grey;
                border-radius: 4px;
                font-size: 16px;
                p {
                    text-align: center;
                }

                &:first-child
                {
                    margin-right: 1%
                }

                &:nth-child(3)
                {
                    width: 99%;
                    text-align: center;
                    margin-top: 8px;

                    p
                    {
                        max-width: 450px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                &:last-of-type
                {
                    //margin-left: 1%;
                    //margin-top: 25px;
                }
            }
        }
    }
    label {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding-right: 0;
    }
    p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
    }
    .middleColumn {
        // margin: 20px 0;
    }
    .checkbox {
        margin-top: 25px;

        .checkbox
        {
            display: inline-block;
        }
        label {
            display: inline-block;
            font-family: $default-font !important;
            font-size: 15px;
        }
    }
    .Actions {
        text-align: center;
    }

    #Form_UploadForm_SupportingDocument2_Holder
    {
        vertical-align: top;
    }
}

#Form_OnlineApplicationFormOne {
    label.left {
        background-color: $light_grey;
        height: 50px;
        width: 100%;
        display: inline-block;
        line-height: 55px;
        padding-left: 20px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
        font-size: 20px;
        text-align: left;
    }
}

#Form_OnlineApplicationFormTwo #PartnerProofDropzone
{
    ul.file-attachment-field-previews
    {
        text-align: left;
    }

    p
    {
        font-size: 16px;
        line-height: 18px;
    }
}

#Form_OnlineApplicationFormThree {
    fieldset .field.CompositeField.fieldgroup {
        margin-top: 0;

        & > div {
            .fieldholder-small {
                flex-direction: column;

                label {
                    width: 100%;
                    text-align: left;
                    margin-top: 15px;
                }
            }

            .fieldgroup-field {
                &:first-child {
                    label {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

#Form_OnlineApplicationFormFour {
    fieldset .field .readonly,
    div input{
        margin-bottom: 20px;
    }
}

#Form_RegistrationForm {
    fieldset{
        @media (max-width: 850px) {
            padding: 10px;
        }
        div {
            display: flex;
            flex-wrap: wrap;
        }
        .info-field {
            @media (max-width: 850px) {
                margin: 0;
            }
        }
        label {
            text-align: left;
            &.left {
                text-align: left;
                display: inline-block;
                line-height: 65px;
                min-width: 155px;
                max-width: 200px;
                width: 100%;
            }
        }
    }
    .field.checkbox {
        label {
            margin: 3px;;
        }
        margin: 10px 0 20px 225px;
        @media (max-width: 850px) {
            margin: 0;
        }
    }
}

.fieldholder-small {
    @media (max-width: 850px) {
        flex-wrap: wrap;
        label {
            text-align: left !important;
            width: inherit;
        }
    }
}

.orange-asterisk {
    color: $brand_orange;
    font-family: inherit;
}

#FacilityAccess {
    @media (max-width: 900px) {
        margin-bottom: 0;
    }
}

label[for="Form_OnlineApplicationFormTwo_PartnerProof"] {
    font-family: $default-font;
}

#Form_OnlineApplicationFormTwo_FacilityAccess {
    display: block;
    margin-top: 10px;
    font-size: 0;

    .valYes,
    .valNo {
        display: inline-block;
        width: 50%;

        @media (max-width: 900px) {
            width: 100%;
        }
    }

    .valOther {
        margin-left: 50%;

        @media (max-width: 900px) {
            margin-left: 0;
        }
    }

    &.readonly {
        flex-direction: column;
    }
}

#Form_OnlineApplicationFormTwo_FacilityAccessInfo {
    min-height: 222px;

    &.readonly {
        margin-top: 44px;
    }
}

#Form_OnlineApplicationFormTwo_FacilityAccessAge_Holder {
    margin-bottom: 30px;
}

#Form_OnlineApplicationFormTwo_FacilityAccessInfo_Holder {
    width: 50%;
    float: right;
    margin-top: -18px;

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 0;
    }
}

#Form_OnlineApplicationFormTwo_FacilityAccessType_Holder,
#Form_OnlineApplicationFormTwo_FacilityAccessLocation_Holder,
#Form_OnlineApplicationFormTwo_FacilityAccessAge_Holder {
    width: 40%;
    float: left;
    margin-top: 8px;

    @media (max-width: 900px) {
        width: 100%;
    }
}

#Form_OnlineApplicationFormTwo_FacilityAccessType_Holder {
    margin-top: -35px;

    @media (max-width: 900px) {
        margin-top: 0;
    }

    &.readonly {
        margin-top: 0;
    }
}

#Form_OnlineApplicationFormTwo_InternalResources_Holder {
    clear: both;
}

#Form_UploadForm_Disclaimer_Holder {

    label
    {
        width: auto;
    }
    &.readonly {
        margin-top: 10px;
    }
}

#Form_RegistrationForm_error {
    color: #ec0e0e;
    margin-bottom: 11px;
    font-weight: bold;
}

#CaptchaMemberLoginForm_LoginForm_Captcha_Holder {
    margin-top: 35px;
}

@keyframes radio {
    0% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
    }
    0% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 0%, white 0%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 0%, white 0%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 0%, white 0%);
    }
    20% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 9%, white 10%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 9%, white 10%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 9%, white 10%);
    }
    40% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 19%, white 20%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 19%, white 20%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 19%, white 20%);
    }
    60% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 29%, white 30%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 29%, white 30%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 29%, white 30%);
    }
    80% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 39%, white 40%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 39%, white 40%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 39%, white 40%);
    }
    100% {
        background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
        background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
        background-image: radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
    }
}

.field-horizontal {
    display: flex;
    border-bottom: 1px $super_light_grey solid;
    padding: 16px 0;
    margin-bottom: 16px;

    .field-horizontal--row {
        display: flex;
        flex: 1 0 0;

        input[type=radio] {
            height: 28px;
            width: 28px;
            margin: 0;
            position: relative;
            border: 1px $brand_green solid;
            border-radius: 28px;
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
        }

        input[type=radio]:checked {
            animation-name: radio;
            animation-duration: 0.1s;
            animation-iteration-count: 1;
            background-image: -moz-radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
            background-image: -webkit-radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
            background-image: radial-gradient(circle, $brand_green, $brand_green 49%, white 50%);
        }

        &.valoffering input[type=radio] {
            border: 1px $brand_orange solid;
        }

        &.valoffering input[type=radio]:checked {
            border: 1px $brand_orange solid;
            background-image: -moz-radial-gradient(circle, $brand_orange, $brand_orange 49%, white 50%);
            background-image: -webkit-radial-gradient(circle, $brand_orange, $brand_orange 49%, white 50%);
            background-image: radial-gradient(circle, $brand_orange, $brand_orange 49%, white 50%);
        }

        input[type=radio]:focus {
            outline: none;
        }

        .field-horizontal-row-input {
            margin-right: 8px;
        }
        .field-horizontal--row-title {
            font-family: $medium_font;
        }
    }
}

