// main: style.scss

$site_width: 1140px;
$global_margin_x: 12%;


*,
*::before,
*::after
{
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
// end .panel--landing *
// end .panel--landing *::before
// end .panel--landing *::after

html
{
    box-sizing: border-box;
}

body
{
    min-width: 350px;
	max-width: 2000px;
	margin: 0 auto;

    background-color: $super_light_grey;
}

img
{
    max-width: 100%;
}
// end img

figure
{
    img
    {
        max-width: 100%;
    }
    // end img
}
// end figure

.content-container
{
    position: relative;
    padding: 55px 0;
    background-color: $super_light_grey;
    margin: 0;

    h2
    {
        margin: 0 auto;
        // width: 500px;
        margin-bottom: 30px;
    }

    section.page-intro
    {
        text-align: center;
        // width: 70%;
        margin: auto;
        margin-bottom: 55px;

        p,
        span
        {
            // font-size: 10px;
        }
    }
    .site_width {
        max-width: $site_width;
        margin: 0 auto;
    }

	p a {
		color: $brand_orange;
		text-decoration: underline;
	}
}

@media (max-width: 767px) {
	.content-container {
		.blog &,
		.blogpost & {
			padding-top: 0;
		}
	}
}

.two-column
{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    aside
    {
        width: (100% / 3);

        @media (max-width: 850px)
        {
            display: none;
        }
    }

    .content
    {
        width: (100% / 3) * 2;
        padding-left: (100% / 12);

        h1
        {
            display: inline-block;
        }

        @media (max-width: 850px)
        {
            width: 100%;
            padding-left: 0;
        }
    }
}

.homepage {
    aside {
        img {
            height: 350px;
        }
    }
}

.submission-steps {
    width: 100%;
    margin: auto;
    section {
        opacity: 1;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
        &.inactive {
            opacity: 0.3;
            pointer-events: none;
        }
        margin: 25px 0;
        border: 1px solid $light_grey;
        border-radius: 4px;
        background: $white;
        h3 {
            font-size: 26px;
        }
        p {
            margin-top: 20px;
            font-size: 16px;
            line-height: 25px;
        }
        .step-panel {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            border-radius: 3px;
            min-height: 170px;
            margin: 30px;
            aside {
                @media (max-width: 850px) {
                    width: auto;
                    text-align: center;
                }
                width: 50%;
            }
        }
        .step-panel-double {
            border-radius: 3px;
            min-height: 170px;
            margin: 30px;
            .actions-double {
                display: block;
                margin: 30px 0;
                text-align: center;
                font-size: 0;
                span {
                    display: inline-block;
                    font-size: 20px;
                    margin: 55px 20px 20px 20px;
                    width: 10%;
                    @media (max-width: 1139px) {
                        display: none;
                    }
                }
                div {
                    display: inline-block;
                    max-width: 380px;
                    width: 45%;
                    font-size: 16px;
                    border: 1px solid $light_grey;
                    padding: 40px;
                    vertical-align: middle;
                    //margin: 10px 0;
                    .btn.bg-green {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .button-bar {
            height: 80px;
            background-color: $mid_grey;
            text-align: center;
            border-radius: 0 0 4px 4px;
            button {
                margin: 15px;
            }
        }
    }
}
