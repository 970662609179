// main: ../style.scss

.comment-panel
{
    margin-top: 21px;

    background-color: $white;

    div.compose
    {
        padding: 40px 40px 20px 40px;

        h3
        {
            font-size: 22px;
        }

        textarea
        {
            -webkit-appearance: none;
            @include border_radius(0);
        }

        @media screen and (max-width: 768px)
        {
            padding: 21px 21px 7px 21px;
        }
        // end @media screen and (max-width: 768px)
    }

    div.comments
    {
        padding: 20px 40px 40px 40px;

        #Form_CommentForm
        {
            display: none;
        }

        fieldset
        {
            margin-left: 85px;
        }

        .reply-link
        {
            margin-left: 85px;

            color: $mid_grey;
            cursor: pointer;

            font-size: 14px;
        }

        textarea
        {
            -webkit-appearance: none;
			@include border_radius(0);
			resize: vertical;
        }

        @media screen and (max-width: 768px)
        {
            padding: 7px 21px 21px 21px;
        }
        // end @media screen and (max-width: 768px)
    }

    fieldset
    {
        padding: 0;

        border: 0;
    }

    .action
    {
        margin: 0;
    }
}

.comment
{
    display: flex;
    margin-top: 20px;

    .avatar
    {
        margin-right: 20px;

        img
        {
            height: 50px;
        }
    }

    .text
    {
        margin: 20px 15px;

        .author
        {
            color: $brand_orange;

            font-weight: bold;
            font-size: 14px;
        }

        .dot
        {
            color: $mid_grey;

            margin: 0 10px;
        }

        .date
        {
            color: $mid_grey;

            font-size: 14px;
        }

        .body
        {
            margin: 5px 0;

            font-size: 15px;
            line-height: 20px;
        }
    }
}

.comment-nest
{
    margin-left: 65px;

    .avatar
    {
        margin-right: 10px;

        img
        {
            height: 40px;
            margin-left: 20px;
        }
    }

    .text
    {
        margin: 15px 15px 0 15px;
    }
}
